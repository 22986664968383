import React, { useState, useEffect } from 'react';
import Select, { components, PlaceholderProps } from 'react-select';

function SelectReact(props) {

  // console.log(props);
  const currentBank = props.dvalue;
  //Значение по умолчанию которое нам передали
  const defaultValueSelect = { value: props.dvalue, label: props.dvalue };

  const existBank = (nameBankSearch) => {
    if (nameBankSearch == "") nameBankSearch = "Не указано название банка"
    var found = false;
    for(var i = 0; i <  props.list.length; i++) {
        if ( props.list[i].bankname == nameBankSearch) {
            found = true;
            break;
        }
    }
    return found
  }

  //Меняем стандартный значок выпадающего списка на картинку svg лупа
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <SearchIcon />
        </components.DropdownIndicator>
      )
    )
  }

  //svg лупа
  const SearchIcon = () => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="38" cy="40" r="20.5" stroke="currentColor" strokeWidth="7" />
      <path
        d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
        fill="currentColor"
      />
    </svg>
  )

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };


  const [selected, setSelected] = useState(defaultValueSelect);
  useEffect(() => { setSelected(defaultValueSelect) }, [currentBank])
  //setSelected(defaultValue);
  // console.log("UseState:" + JSON.stringify(selected));

  const handleChange = (value) => {
    setSelected(value);
    // console.log('1123')
    // console.log(value.value)
    return value.value
    // setEditBank(value.value)
    // console.log("handleChange State: " + JSON.stringify(selected))
  };

  //Настройка стиля select
  const customStyles = {
    // значок очистки вправо
    /* clearIndicator: (base) => ({
      ...base,
      position: 'absolute',
      right: 0,
    }), */
    clearIndicator: (base) => ({
      ...base,
      padding: "3px !important",
    }),
    // Стилья для placeholder
    placeholder: (base) => ({
      ...base,
      gridArea: '1/1/1/1',
      fontSize: "1em",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "0.9em",
      width: "max-content",
      minWidth: "100%",
      zIndex: 9999,
    }),
    menuPortal: provided => ({
      ...provided,
      fontSize: "0.9em",
      zIndex: 9999
    }),
    container: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "calc(1.5em + 0.5rem + 150px)",
    }),
    control: (styles, state, base) => ({
      ...styles,
      width: "max-content",
      minWidth: "100%",
      minHeight: "auto",
      textAlign: "left",
      boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(13,110,253,.25)" : 0,
      borderColor: state.isFocused ? "#86b7fe" : "#ced4da",
      "&:hover": {
        borderColor: state.isFocused ? "#86b7fe" : "#ced4da"
      },
      ...base,
      //flexDirection: 'row-reverse',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 5px",
    }),
    singleValue: (base, state) => ({
      ...base,
      borderBottom: existBank(currentBank) ?
        "0px solid #fff" : "3px solid #dc3545",
      display: "inline-block",
      overflow: "visible",
      whiteSpace: "nowrap",
      position: "absolute",
      textDecoration: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      backgroundColor: state.isSelected ? '#0d6efd' : (state.isFocused ? '#dfe0e1' : provided.backgroundColor),
      '&:hover': {
        backgroundColor: state.isSelected ? '#0d6efd' : '#dfe0e1',
      }
    }),
  }

  return (
    <Select
      menuPortalTarget={document.body}
      value={selected}
      defaultValue={defaultValueSelect}
      options={props.list.map((option) => ({ value: option.bankname, label: option.bankname }))}
      isClearable={false}
      isSearchable={true}
      components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null, /* DropdownIndicator, */ Placeholder }}
      styles={customStyles}
      placeholder={'Поиск по названию банка ...'}
      // onChange={setEditBank(selected)}
      onChange={props.onChange}
     
    />

  );


}

export default SelectReact;