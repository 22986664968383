import { $authHost, $host, $authHost_test } from "./index";
import jwt_decode from 'jwt-decode'
export const registration = async (email, password, name, surname, birthday, gender, phone, position) =>{
    // const {data} = await $host.post('api/user/registration', {email, password, role:"ADMIN"})
    const {data} = await $host.post('api/user/registration', {email, password, name, surname, birthday, gender, phone, position})
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const login = async (email, password) =>{
    const {data} = await $host.post('api/user/login', {email, password})
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const check = async () =>{
    const {data} = await $authHost.get('api/user/auth')
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const fetchPersons = async (
    birthday,
    // limit = 4,
    // page
  ) => {
    const { data } = await $authHost.get('api/persons', {
      // const { data } = await $authHost_test.get('api/persons', {
      params: {
        birthday,
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };

  export const itSettings = async () => {
    const { data } = await $authHost.get('api/it/settings');
    // console.warn(data);
    return data;
  };

  export const itManager = async () => {
    const { data } = await $authHost.get('api/it/manager');
    // console.warn(data);
    return data;
  };

  export const setings_save = async (set) =>{
    const {data} = await $authHost.post('api/it/settings', {set})
    return data;  
}

    export const usersSettings = async () => {
      const { data } = await $authHost.get('api/it/users');
      // console.warn($authHost_test);
      return data;
    };

    export const user_setings_save = async (set) =>{
      const {data} = await $authHost.post('api/it/users', {set})
      return data;
    } 

    export const get_citys = async () => {
      const { data } = await $authHost.get('api/user/sftx');
      // console.warn(data);
      return data;
    };

    export const get_users = async () => {
      const { data } = await $authHost.get('api/user/get_users');
      // console.warn(data);
      return data;
    };


    export const edit_citys = async (id, cityId) =>{
      const {data} = await $authHost.post('api/user/edit_citys', {id, cityId})
      return data
  }

  export const edit_role = async (id, role) =>{
    const {data} = await $authHost.post('api/user/edit', {id, role})
    return data
}

export const changePassword = async (id, password) =>{
  const {data} = await $authHost.post('api/user/changepassword', {id, password})
  return data
}