import React , { useContext } from "react";
import { Context } from "../index";
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from "react-router-dom";
import { ADMIN_ROUTE, INDEX_ROUTE, LOGIN_ROUTE } from "../utils/consts";
import {observer} from 'mobx-react-lite'
import {useNavigate } from "react-router-dom"

const NavBar = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        localStorage.removeItem('token')
        navigate(INDEX_ROUTE)
    }
    //console.log(user.user.role)
    return(
        <Navbar bg="dark" variant="dark">
            <Container>
             
                {
                (user.isAuth && (user.user.role==='ADMIN' || user.user.role==='RUK+')) ?
                    <>
                        <NavLink style={{"color":"white","fontWeight":"800"}} to={INDEX_ROUTE}>Aktiv-kredit</NavLink>
                        <Nav className="ml-auto">
                            <NavLink  role="button" className="nav-link" to={ADMIN_ROUTE}>Настройки</NavLink>
                            <Nav.Link onClick={() => logOut()}>Выйти</Nav.Link>
                        </Nav>
                    </>
                    : (user.isAuth && user.user.role==='RUK') ?
                    <>
                        <NavLink style={{"color":"white","fontWeight":"800"}} to={INDEX_ROUTE}>Aktiv-kredit</NavLink>
                        <Nav className="ml-auto">
                            {/* <NavLink  role="button" className="nav-link" to={ADMIN_ROUTE}>Личный кабинет</NavLink> */}
                            {/* <Nav.Link onClick={() => navigate(ADMIN_ROUTE)}>Личный кабинет</Nav.Link> */}
                            <Nav.Link onClick={() => logOut()}>Выйти</Nav.Link>
                        </Nav>
                    </>
                    : (user.isAuth && user.user.role==='MAN') ?
                    <>
                        <NavLink style={{"color":"white","fontWeight":"800"}} to={INDEX_ROUTE}>Aktiv-kredit</NavLink>
                        <Nav className="ml-auto">
                            {/* <NavLink  role="button" className="nav-link" to={ADMIN_ROUTE}>Личный кабинет</NavLink> */}
                            {/* <Nav.Link onClick={() => navigate(ADMIN_ROUTE)}>Личный кабинет</Nav.Link> */}
                            <Nav.Link onClick={() => logOut()}>Выйти</Nav.Link>
                        </Nav>
                    </>
                    // : (user.isAuth && user.user.role==='PARTNER')?
                    // <Nav className="ml-auto">
                    //     <Nav.Link onClick={() => navigate(ADMIN_ROUTE)}>Личный кабинет</Nav.Link>
                    //     <Nav.Link onClick={() => navigate(ADMIN_ROUTE)}>Личный кабинет партнера</Nav.Link>
                    //     <Nav.Link onClick={() => logOut()}>Выйти</Nav.Link>
                    // </Nav>
                    :
                    <>
                    <NavLink style={{"color":"white","fontWeight":"800"}} to={LOGIN_ROUTE}>Aktiv-kredit</NavLink>
                    <Nav className="ml-auto">
                        <NavLink  role="button" className="nav-link" to={LOGIN_ROUTE}>Авторизация/Регистрация</NavLink>
                        {/* <Nav.Link  onClick={() =>navigate(LOGIN_ROUTE)}>Авторизация/Регистрация</Nav.Link> */}
                    </Nav>
                    </>
                }     
            </Container>
        </Navbar>
    );

});

export default NavBar;