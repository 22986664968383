import { observer } from "mobx-react-lite";
import React , {useMemo, useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Placeholder, Row, Table, ListGroup,Badge,InputGroup } from "react-bootstrap";

import { Context } from "../index";
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import { INDEX_ROUTE, LOGIN_ROUTE } from "../utils/consts";
import {  fetchSale4, fetchSale4AllparthenrsCheck, fetchSale4AkiParthners, fetchSale4_f_man} from "../http/salesAPI";
import WidthModal from "../components/modals/WidthModal"

import TablePagination from "../components/pagination/TablePagination";

import { CSVLink } from 'react-csv'

import { confirm } from "../components/confirm/Confirmation";

const priceSet = function(data){
    /*
     * В переменной price приводим получаемую переменную в нужный вид:
     * 1. принудительно приводим тип в число с плавающей точкой,
     *    учли результат 'NAN' то по умолчанию 0
     * 2. фиксируем, что после точки только в сотых долях
     */
    var price       = Number.prototype.toFixed.call(parseFloat(data) || 0, 0),
        //заменяем точку на запятую
        price_sep   = price.replace(/(\D)/g, ","),
        //добавляем пробел как разделитель в целых
        price_sep   = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

    return <b>{price_sep}</b>;
};

const timeConverter = function(UNIX_timestamp){
    if (!UNIX_timestamp){
        return '';
    }
	var a = new Date(UNIX_timestamp * 1000);
	var months = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var hour = a.getHours();
	var min = a.getMinutes();
	var sec = a.getSeconds();
	// var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
	// var time = year+''+a.getMonth();
	var time = ('0' + a.getDate()).slice(-2)+'.'+('0' + a.getMonth()).slice(-2)+'.'+year;
	return time;
  }

const useSortableData = (items, config = {key: 'city', direction: 'ascending'}) => {
    const [sortConfig, setSortConfig] = React.useState(config);
    const [cityfarr, setCityfArr] = useState([])
    const [parthnersfarr, setParthnersfArr] = useState([])
    const [statusfarr, setStatusfArr] = useState([])
    const [filterfarr, setFilterfArr] = useState({})
    const [status2, setStatus2] = useState({})
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }


        if (cityfarr.length!==0)
        sortableItems=sortableItems.filter(function(f) {
             return cityfarr.includes(f.city_id)   
        });
        if (parthnersfarr.length!==0)
        sortableItems=sortableItems.filter(function(f) {
             return parthnersfarr.includes(f.parthner_id)   
        });
        if (Object.keys(filterfarr).length!==0)
        sortableItems=sortableItems.filter(function(f) {
             return !filterfarr[f.parthner_id.split("_")[1]]
        });
        if (statusfarr.length!==0)
        sortableItems=sortableItems.filter(function(f) {
             return statusfarr.includes(f.status)   
        });

        let ff={}
        sortableItems.map((item4,index4) => {
                if (!ff[item4["status"]])
                ff[item4["status"]]={count:0}
                ff[item4["status"]].count++
            })
            setStatus2(ff)
      return sortableItems;
    }, [items, sortConfig, cityfarr, parthnersfarr, statusfarr, filterfarr]);
  
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };


    const cityFilter =  (value) =>{
        if (!value) {
            setCityfArr([]) 
            return
        }
        if (!cityfarr.includes(value))
        cityfarr.push(value)
        else
        delete(cityfarr[cityfarr.indexOf(value)])
        setCityfArr(JSON.parse(JSON.stringify(cityfarr.filter(element => element != null))))
    }  
    const parthnersFilter =  (value) =>{
        if (!value) {
            setParthnersfArr([]) 
            return
        }
        if (!parthnersfarr.includes(value))
        parthnersfarr.push(value)
        else
        delete(parthnersfarr[parthnersfarr.indexOf(value)])
        setParthnersfArr(JSON.parse(JSON.stringify(parthnersfarr.filter(element => element != null))))
    } 
    const statusFilter =  (value) =>{
        if (!value) {
            setStatusfArr([]) 
            return
        }
        if (!statusfarr.includes(value))
        statusfarr.push(value)
        else
        delete(statusfarr[statusfarr.indexOf(value)])
        setStatusfArr(JSON.parse(JSON.stringify(statusfarr.filter(element => element != null))))
    } 

    const filterFilter =  (value) =>{
        setFilterfArr(JSON.parse(JSON.stringify(value)))
    } 

    
            
  
    return { items: sortedItems, requestSort, sortConfig, cityFilter, parthnersFilter, statusFilter, filterFilter, cityfarr, parthnersfarr, statusfarr, filterfarr,status2 };
  };

const Sale4 = observer(() => {

    const location = useLocation()

    let s_d=''
    let e_d=''
    try {
        if (location.search.split('?')[1].split('&')[0].split('=')[0]==='startdate'){
            s_d=location.search.split('?')[1].split('&')[0].split('=')[1]
        }
        if (location.search.split('?')[1].split('&')[1].split('=')[0]==='enddate'){
            e_d=location.search.split('?')[1].split('&')[1].split('=')[1]
        }
    } catch (error) {
        //console.error(error)
    }
    
    const click = async () =>{
       try {
           setTrigger(!trigger)
           setLoading(true)
       } catch (error) {
            console.error(error)
       }
      
       
   }

  

    const {user} = useContext(Context)
    const navigate = useNavigate()

    // const { sales } = useContext(Context);
    const [sales, setSales] = useState([])

    const [table, setTable] = useState([])
    const [table2, setTable2] = useState([])
    
    const [tableHead, setTableHead] = useState([])
    const [tableHeadCsv, setTableHeadCsv] = useState([])
    const [modal, setModal] = useState({show:false, header:'', body:''});

    const [cityf, setCityf] = useState({})
    const [parthnersf, setParthnersf] = useState({})
    const [statusf, setStatusf] = useState({})
  

    const [searchParthners, setSearchParthners] = useState('')
    
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [akiParthners, setAkiParthners] = useState({});
    const [akiParthnersName, setAkiParthnersName] = useState({});

    const [allParthnersCheck,setAllParthnersCheck] = useState(false)

    async function hideParthner (value,p,f){
        let m=[]
        m.push(<span style={{color:"red"}} key="s1">Скрыть партнера </span>)
        m.push(<b key="b1">{p} <span style={{color:"orange"}}> ({f})</span>?</b>)
        
        if (await confirm(m)) {
            fetchSale4AllparthenrsCheck(value.split('_')[1],p).then((data) => {
                if (data.succsess){
                    filterFilter(data.succsess)
                    // console.log(data.succsess)
                }
            });
        } else {
            console.log("NO")
        }
    } 
    async function showParthner(value,p){
        let m=[]
        m.push(<span style={{color:"red"}} key="s1">Отобразить партнера </span>)
        m.push(<b key="b1">{p}?</b>)
        
        if (await confirm(m)) {
            
            fetchSale4AllparthenrsCheck(value,p).then((data) => {
                if (data.succsess){
                    filterFilter(JSON.parse(JSON.stringify(data.succsess)))
                    setModal({show:false})
                }
            });
        } else {
            console.log("NO")
        }
    } 




    let date = new Date()
   // http://localhost:3000/sale1?startdate=2022-08-12&enddate=2022-08-12
    if (s_d==='' || e_d===''){
        // if (date.getDate()===1){
            // s_d=date.getFullYear()+'-'+(('0' + date.getMonth()).slice(-2))+'-'+(('0' + (date.getDate()-1)).slice(-2))
        s_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + date.getDate()).slice(-2))
        // } else{
            // s_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + (date.getDate()-1)).slice(-2))
        // }
        
        e_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + date.getDate()).slice(-2))
    } else {

    }

    const [startDate, setStartDate] = useState(s_d)
    const [endDate, setEndDate] = useState(e_d)
    
    const [loading, setLoading] = useState(true)
    const [trigger, setTrigger] = useState(true)

    useEffect(() => {
        location.search="?startdate="+startDate+"=&enddate="+endDate
        navigate(location)
        fetchSale4(startDate,endDate, allParthnersCheck).then((data) => {
            if(data.succsess){
                let thead=[
                    {   
                        id:"city",
                        name:"Город"
                    },
                    {   
                        id:"parthner",
                        name:"Партнер"
                    }
                ]
                let theadcsv=[
                    {   
                        key:"city",
                        label:"Город"
                    },
                    {   
                        key:"parthner",
                        label:"Партнер"
                    },
                    {   
                        key:"parthner_id2",
                        label:"ID партнера"
                    },
                    {   
                        key:"link",
                        label:"Ссылка на привлечение"
                    },
                    {   
                        key:"manager",
                        label:"Менеджер"
                    },
                    {   
                        key:"vidano",
                        label:"Дата в статусе КРЕДИТ ВЫДАН"
                    }
                ]
                Object.keys(data.succsess.coll).map((item2,index2) => {
                    thead.push({   
                        id:item2+"_count",
                        name:"Кол-во за "+data.succsess.coll[item2].name
                    },{   
                        id:item2+"_price",
                        name:"Сумма за "+data.succsess.coll[item2].name
                    })
                    theadcsv.push({   
                        key:item2+"_count",
                        label:"Кол-во за "+data.succsess.coll[item2].name
                    },{   
                        key:item2+"_price",
                        label:"Сумма за "+data.succsess.coll[item2].name
                    })
                })
                 thead.push({   
                    id:"itog_count",
                    name:"Итого шт."
                },
                {   
                    id:"itog_price",
                    name:"Итого сумма"
                },
                {   
                    id:"status",
                    name:"Статус"
                })
                theadcsv.push({   
                    key:"itog_count",
                    label:"Итого шт."
                },
                {   
                    key:"itog_price",
                    label:"Итого сумма"
                },
                {   
                    key:"status",
                    label:"Статус"
                })
                setTableHead(thead)
                setTableHeadCsv(theadcsv)
                let tbody=[]
                let cityf={}
                let parthnersf={}
                let statusf={}
                Object.keys(data.succsess).map((item2,index2) => {
                    if(item2!=='coll' && item2!=='ist_fortuna' && item2!=='ist_8250'  && item2!=='filter')
                        Object.keys(data.succsess[item2].parthners).map((item3,index3) => {
                            let tr={
                                id:"tr_"+item2+'_'+item3,
                                city_id:item2,
                                parthner_id:item2+"_"+item3,
                                parthner_id2:item3,
                                city:data.succsess[item2].name,
                                parthner:data.succsess[item2].parthners[item3].name
                            }
                            if (!cityf[item2])
                                cityf[item2]={count:0,active:false}
                            cityf[item2].name=data.succsess[item2].name
                            cityf[item2].count++
                            if (!parthnersf[item2+'_'+item3])
                                parthnersf[item2+'_'+item3]={}
                            parthnersf[item2+'_'+item3].name=data.succsess[item2].parthners[item3].name
                            



                            Object.keys(data.succsess.coll).map((item4,index4) => {
                               if (data.succsess[item2].parthners[item3].dates[item4]){
                                
                                    tr[item4+"_count"]=data.succsess[item2].parthners[item3].dates[item4].count
                                    tr[item4+"_price"]=data.succsess[item2].parthners[item3].dates[item4].price
                                    
                                    tr[item4+"_count_f"]=item2+"_"+item3+"_"+item4
                                } else {
                                    tr[item4+"_count"]=0
                                    tr[item4+"_price"]=0
                                }
                            })
                            tr['itog_count']=data.succsess[item2].parthners[item3].count
                            tr['itog_price']=data.succsess[item2].parthners[item3].price

                            let status=0
                            for (let d in data.succsess.coll){
                                if (data.succsess[item2].parthners[item3].dates[d])
                                    status=0
                                 else status++
                                
                            }
                            tr["status"]=(status==0)?'Активен'
                            :(status==1)?'Внимание'
                            :'Повышенное внимание'
                            tr["color"]=(status==0)?'#89F169'
                            :(status==1)?'#FFFB96'
                            :'#FFB732'
                            // if (!statusf[tr["status"]])
                            //     statusf[tr["status"]]={count:0}
                            // statusf[tr["status"]].count++
                            tbody.push(tr)
                            // console.log(tr)
                        })
                       
                })

               
                        
                       
                filterFilter(data.succsess.filter)
                cityFilter(null)
                parthnersFilter(null)
                statusFilter(null)

                setCityf(cityf)
                setParthnersf(parthnersf)
                // setStatusf(statusf)
                // console.log(statusf)
                
                setTable(tbody)
                setSales(data);
                setCurrentPage(1)


                fetchSale4AkiParthners(true).then((data2) => {
                    if (data2.succsess){
                        setAkiParthners(data2.succsess)
                        let fortuna_id=[]
                        for (let item4 of tbody){
                            for (let pp_f in data2.succsess){
                                // console.log(item4.parthner_id.split('_')[1]+'*'+pp_f);
                                if(item4.parthner_id.split('_')[1]==pp_f)
                                    fortuna_id.push(data2.succsess[pp_f])
                            }
                            
                        }
                        // console.log(fortuna_id)
                        if (fortuna_id.length!=0){
                            fetchSale4_f_man(fortuna_id).then((data2) => {
                                if (data2.succsess){
                                    // console.log(data2.succsess)
                                    setAkiParthnersName(data2.succsess)
                                } else{
                                    setAkiParthnersName({})
                                }


                                Object.keys(data.succsess.ist_fortuna).map((item2,index2) => {
                                    
                                    
                                    // let lid = akiParthners[item2]
                                    // console.log(akiParthnersName[lid])
                                    let enn = 'fortuna'
                                    // akiParthnersName[akiParthners[item4.parthner_id.split('_')[1]]]
                                    if (akiParthnersName[akiParthners[item2]]){
                                        console.log(111)
                                        let en = akiParthnersName[akiParthners[item2]]['reg']
                                       
                                        switch (en){
                                            case 945605:
                                                enn = 1745923 //Пенза
                                            break;
                                            case 955211:
                                                enn = 3999631 //КЦ Пермь
                                            break;
                                            case 945597:
                                                enn = 1745926 //ф.Ульяновск
                                            break;
                                            case 945595:
                                                enn = 721543 //ф.Пермь
                                            break;
                                            case 945599:
                                                enn = 1843345 //Магнитогорск
                                            break;
                                            case 945601:
                                                enn = 1843756 //Ярославль
                                            break;
                                            case 945603:
                                                enn = 3145828 //Уфа
                                            break;
                                            case 945607:
                                                enn = 1843759 //Самара
                                            break;
                                            case 955325:
                                                enn = 3999637 //КЦ Ульяновск
                                            break;
                                            
                                        }
                                    }
                
                                    let tr={
                                        id:"tr_"+enn+"_"+item2,
                                        city_id:enn,
                                        parthner_id:enn+"_"+item2,
                                        city:"я без лидов fortuna",
                                        parthner:data.succsess.ist_fortuna[item2]
                                    }
                                    if (!cityf[enn])
                                        cityf[enn]={count:0,active:false}
                                    cityf[enn].name="я без лидов fortuna"
                                    cityf[enn].count++
                                    if (!parthnersf[enn+"_"+item2])
                                        parthnersf[enn+"_"+item2]={}
                                    parthnersf[enn+"_"+item2].name=data.succsess.ist_fortuna[item2]
                                    
                
                
                
                                    Object.keys(data.succsess.coll).map((item4,index4) => {
                                            tr[item4+"_count"]=0
                                            tr[item4+"_price"]=0
                                    })
                                    tr['itog_count']=0
                                    tr['itog_price']=0
                
                                    tr["status"]="Нет лидов"
                                    tr["color"]="red"
                                    // if (!statusf[tr["status"]])
                                    //     statusf[tr["status"]]={count:0}
                                    // statusf[tr["status"]].count++
                                    tbody.push(tr)
                                    // console.log(tr)
                                })
                
                                Object.keys(data.succsess.ist_8250).map((item2,index2) => {
                                    // let lid = akiParthners[item2]
                                    // console.log(akiParthnersName[lid])

                                    let tr={
                                        id:"tr_f8250_"+item2,
                                        city_id:"f8250",
                                        parthner_id:"f8250_"+item2,
                                        city:"я без лидов 8250",
                                        parthner:data.succsess.ist_8250[item2]
                                    }
                                    if (!cityf["f8250"])
                                        cityf["f8250"]={count:0,active:false}
                                    cityf["f8250"].name="я без лидов 8250"
                                    cityf["f8250"].count++
                                    if (!parthnersf["f8250_"+item2])
                                        parthnersf["f8250_"+item2]={}
                                    parthnersf["f8250_"+item2].name=data.succsess.ist_8250[item2]
                                    
                
                
                
                                    Object.keys(data.succsess.coll).map((item4,index4) => {
                                            tr[item4+"_count"]=0
                                            tr[item4+"_price"]=0
                                    })
                                    tr['itog_count']=0
                                    tr['itog_price']=0
                
                                    tr["status"]="Нет лидов"
                                    tr["color"]="red"
                                    // if (!statusf[tr["status"]])
                                    //     statusf[tr["status"]]={count:0}
                                    // statusf[tr["status"]].count++
                                    tbody.push(tr)
                                })
                            });
                        } 


                    } else{
                        setAkiParthners({})
                    }
                })

                

             
               
            } else {
                setTable([])
                setTableHead([])
                setSales(data.error);
            }
            setLoading(false)
        });
        }, [trigger]);


        
        const { items, requestSort, sortConfig,  cityFilter, parthnersFilter, statusFilter, filterFilter, cityfarr, parthnersfarr, statusfarr, filterfarr, status2  } = useSortableData(table);
        
       

        
        function  modalf (i1)  { 
            if(!i1) return
            let ind=i1.split("_")
            let mhead=sales.succsess[ind[0]].parthners[ind[1]].name+" ("+sales.succsess[ind[0]].name+") за "+sales.succsess[ind[0]].parthners[ind[1]].dates[ind[2]].name
            let mbody=[]
            Object.keys(sales.succsess[ind[0]].parthners[ind[1]].dates[ind[2]].lid).map((item4,index4) => {
                mbody.push(
                    <tr   key={item4+'_a_'+index4}>
                        <td className="text-center">{index4+1}</td>
                        <td className="text-center">  
                            <a
                            style={{display:"block"}}
                          
                            href={sales.succsess[ind[0]].parthners[ind[1]].dates[ind[2]].lid[item4].href}
                            target="_blank"
                            >
                                {item4} 
                            </a>
                        </td>
                        <td className="text-center">{priceSet(sales.succsess[ind[0]].parthners[ind[1]].dates[ind[2]].lid[item4].price)}  руб.</td>
                    </tr>
                  
                    
                    
                )

            })
            let t2=
                <Table key="ss" variant="light" responsive="md"  bordered hover className="sale4tablem">
                    <thead>
                        <tr className="table-dark">
                            <th className="text-center">#</th>
                            <th className="text-center">id & ссылка</th>
                            <th className="text-center">Бюджет</th>
                        </tr>
                    </thead>
                    <tbody key='sad'>
                        {mbody}
                    </tbody>
                </Table>

            setModal({show:true, header:mhead, body:t2})

        }

        function  modalf2 ()  { 

            let mhead='Скрытые партнеры'
            let mbody=[]
            if (Object.keys(filterfarr).length==0){
                mbody.push(
                    <tr   key={0}>
                        <td colSpan={3} className="text-center">Нет скрытых партнеров!</td>
                    </tr>  
                )
            } else{
                Object.keys(filterfarr).map((item4,index4) => {
                    mbody.push(
                        <tr   key={item4}>
                            <td className="text-center">{index4+1}</td>
                            <td className="text-center">  
                                    {filterfarr[item4]} 
                            </td>
                            <td className="text-center"
                            style={{cursor:"pointer"}}
                            onClick={() => showParthner(item4,filterfarr[item4])}
                            >
                                ❌
                            </td>
                        </tr>  
                    )
    
                })
            }

            
           
            
            let t2=
                <Table key="ss" variant="light" responsive="md"  bordered hover className="sale4tablem">
                    <thead>
                        <tr className="table-dark">
                            <th className="text-center">#</th>
                            <th className="text-center">Партнер</th>
                            <th className="text-center">❌</th>
                        </tr>
                    </thead>
                    <tbody key='sad'>
                        {mbody}
                    </tbody>
                </Table>

            setModal({show:true, header:mhead, body:t2})

        }


        const getClassNamesFor = (name) => {
            if (!sortConfig) {
              return;
            }
            return sortConfig.key === name ? sortConfig.direction : undefined;
          };

          useEffect(() => {
            if (currentPage >= items.length / pageSize)
              setCurrentPage(1);
          }, [pageSize, items])

          useMemo(() => {

            const firstPageIndex = (currentPage - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
        
            const newData = items.slice(firstPageIndex, lastPageIndex);
        
            setTable2(newData);
            setTotal(items.length)
          }, [currentPage, pageSize, items]);

          function dataFromAsyncProcess() {
            let items2=JSON.parse(JSON.stringify(items))
            items2.map((item4,index4) => {
               if  (akiParthners[item4.parthner_id.split('_')[1]]){
                item4['link']="https://fortunaperm.amocrm.ru/leads/detail/"+akiParthners[item4.parthner_id.split('_')[1]]
                if (akiParthnersName[akiParthners[item4.parthner_id.split('_')[1]]]){
                    item4['manager']= akiParthnersName[akiParthners[item4.parthner_id.split('_')[1]]]['man']
                    item4['vidano']= timeConverter(akiParthnersName[akiParthners[item4.parthner_id.split('_')[1]]]['vidano_date'])
                }
               }
            })
           
            return items2
          }

    return ( 
        <>
        {/* {console.log("render")} */}
        <Container className="pt-2" style={{marginBottom:"70px"}}>
        <Breadcrumb>
        <NavLink className="breadcrumb-item" to={INDEX_ROUTE}>Главная</NavLink>
        <Breadcrumb.Item active>Аналитика</Breadcrumb.Item>
      </Breadcrumb>
             {user.isAuth?
             <>
                <Row>
                    <Col md={12}>
                    <Form className="row">
                    <Form.Group as={Col} xs="6" md="3" className="mt-3" controlId="startDate">
                    <Form.Label>Начальная дата:</Form.Label>
                    <Form.Control
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        type="date"
                        name="startDate"
                        disabled={loading}
                    />    
                    </Form.Group>
                    <Form.Group as={Col} xs="6" md="3" className="mt-3" controlId="endDate">
                    <Form.Label>Конечная дата:</Form.Label>
                    <Form.Control
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        type="date"
                        name="endDate"
                        disabled={loading}
                    />    
                    </Form.Group>
                    <Form.Group as={Col} xs="6" md="3" className="mt-3" controlId="allParthnersCheck">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Check 
                        type="switch"
                        id="allParthnersCheck"
                        label="Партнеры без лидов"
                        name="allParthnersCheck"
                        checked={allParthnersCheck}
                        onChange={e => setAllParthnersCheck(!allParthnersCheck)}
                    />
                    </Form.Group>
            
                    <Form.Group as={Col} xs="6" md="3" className="text-center mt-5 " controlId="get">
                    <Button  onClick={click} disabled={loading} variant="success" className="">Посчитать</Button>
                    </Form.Group>

                    </Form>
                        <hr/>
                        { (sales['succsess'])?
                        <>
                            <Row style={{fontSize:"0.7em"}}>
                                <Col md={4}>
                                    <ListGroup as="ol" style={{maxHeight:"200px", overflow:"auto"}}>
                                    
                                        {(!loading)?
                                            Object.keys(cityf).map((item4,index4) => (
                                                <ListGroup.Item
                                                    active={(cityfarr.includes(item4))?true:false}
                                                    onClick={() => {cityFilter(item4); setCurrentPage(1);}}
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                    style={{cursor:"pointer"}}
                                                    key={item4}
                                                >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{cityf[item4].name}</div>
                                                </div>
                                                <Badge bg="primary" pill>
                                                {cityf[item4].count}
                                                </Badge>
                                                </ListGroup.Item>
                                            ))
                                            :''
                                        }
                                        
                                    </ListGroup>
                                </Col>    
                                <Col md={4}>
                                {(!loading)?
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">🔎</InputGroup.Text>
                                    <Form.Control
                                    placeholder="Поиск"
                                    aria-label="Поиск"
                                    aria-describedby="basic-addon1"
                                    value={searchParthners}
                                    onChange={e => setSearchParthners(e.target.value)}
                                    />
                                    <Button 
                                        variant="outline-secondary"
                                        id="button-addon1"
                                        onClick={e => {setSearchParthners(''); parthnersFilter(null); setCurrentPage(1);}}
                                        >
                                    ❌
                                    </Button>
                                </InputGroup>
                                :
                                ''
                                }
                                    <ListGroup as="ol" style={{maxHeight:"147px", overflowY:"auto", overflowX:"hidden"}}>
                                    
                                        {(!loading)?
                                            Object.keys(parthnersf).map((item4,index4) => (
                                                (parthnersf[item4].name.toLowerCase().includes(searchParthners.toLowerCase()) && (cityfarr.includes(item4.split("_")[0]) || cityfarr.length==0) )?
                                                <ListGroup.Item
                                                    active={(parthnersfarr.includes(item4))?true:false}
                                                    onClick={() =>{ parthnersFilter(item4); setCurrentPage(1);}}
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                    style={{cursor:"pointer"}}
                                                    key={item4}
                                                >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{parthnersf[item4].name}</div>
                                                </div>
                                                </ListGroup.Item>
                                                :""
                                            ))
                                            :''
                                        }
                                        
                                    </ListGroup>
                                </Col>  
                            
                                <Col md={4}>
                                    <ListGroup as="ol" style={{maxHeight:"200px", overflow:"auto"}}>
                                    
                                        {(!loading)?
                                            Object.keys(status2).map((item4,index4) => (
                                                <ListGroup.Item
                                                    active={(statusfarr.includes(item4))?true:false}
                                                    onClick={() => {statusFilter(item4); setCurrentPage(1);}}
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                    style={{cursor:"pointer"}}
                                                    key={item4}
                                                >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{item4}</div>
                                                </div>
                                                <Badge bg="primary" pill>
                                                {status2[item4].count}
                                                </Badge>
                                                </ListGroup.Item>
                                            ))
                                            :''
                                        }
                                        
                                    </ListGroup>
                                </Col>  
                            
                            </Row>
                       
                            {(!loading)?
                            <>
                            <hr/>
                            <Button style={{margin:"0px",padding:"0px"}} variant="warning">
                                <CSVLink style={{margin:"0px",padding:"3px 10px", textDecoration:"none", color:"black"}} data={dataFromAsyncProcess()} filename={'export.csv'} separator={";"} headers={tableHeadCsv}>Выгрузить в CSV</CSVLink>
                            </Button>
                            <Button
                                style={{margin:"0px",padding:"0px",float:"right"}}
                                variant="info"
                                onClick={() => modalf2()}
                                >
                                Показать скрытых
                            </Button>
                            </>
                            
                            :''




                            }
      
                            <div style={{overflow:"auto", maxHeight:"500px"}}>
                                <Table style={{overflow:"auto",fontSize:"0.7em"}} variant={(sales['succsess'])?"light":"danger"} responsive="md"  bordered hover className="sale4table">
                                    <thead>
                                        <tr className="table-dark">
                                            {(!loading)?
                                                    <th 
                                                        title="Блокировка"
                                                        className={"text-center"}
                                                        style={{paddingRight:"20px",cursor:"pointer"}}
                                                        >
                                                    🔒
                                                    </th>
                                                    :      
                                                    <th  style={{color:'white'}} className="text-center">
                                                    <Placeholder  animation="glow">
                                                        <Placeholder xs={2} />
                                                    </Placeholder>
                                                </th> }
                                            {tableHead.map(tr => (
                                                (!loading)?
                                                    <th 
                                                        key={tr.id}
                                                        title={tr.name}
                                                        onClick={() => {requestSort(tr.id); setCurrentPage(1);}}
                                                        className={"text-center "+getClassNamesFor(tr.id)}
                                                        style={{paddingRight:"20px",cursor:"pointer"}}
                                                        >
                                                        {tr.name} 
                                                    </th> 
                                                    :      
                                                    <th key={tr.id} style={{color:'white'}} className="text-center">
                                                    <Placeholder  animation="glow">
                                                        <Placeholder xs={2} />
                                                    </Placeholder>
                                                </th>          
                                                ))}
                                        </tr>
                                    </thead>        
                                    <tbody>
                                        {table2.map(tr => (
                                                    <tr key={tr.id}>
                                                        {(!loading)?
                                                            <td 
                                                                style={{cursor:"pointer"}}
                                                                className="text-center"
                                                                onClick={() => hideParthner(tr.parthner_id,tr.parthner,tr.city)}
                                                                
                                                            >🔒</td>  
                                                            : 
                                                            <td  className="text-center">
                                                            <Placeholder  animation="glow">
                                                                <Placeholder xs={2} />
                                                            </Placeholder>
                                                            </td>  }
                                                        {tableHead.map(td => (
                                                            (!loading)?
                                                            <td 
                                                                style={{background:(td.id=="status")?tr.color:(td.id[0]==="i")?'#9ff5e4':'',color:(tr[td.id]===0)?'silver':"inherit",cursor:(tr[td.id+"_f"])?"pointer":''}}
                                                                key={td.id}
                                                                className="text-center"
                                                                kk={td.id+"_f"}
                                                                onClick={() => modalf(tr[td.id+"_f"])}
                                                            >{
                                                                (td.id[td.id.length-1]==="e")?
                                                                priceSet(tr[td.id])
                                                                :(td.id=='parthner' && akiParthners[tr.parthner_id.split('_')[1]])?
                                                                <a href={"https://fortunaperm.amocrm.ru/leads/detail/"+akiParthners[tr.parthner_id.split('_')[1]]} target="_blank">
                                                                     {tr[td.id]}
                                                                </a>
                                                                :
                                                                tr[td.id]
                                                                }
                                                                </td>  
                                                            : 
                                                            <td  key={td.id} className="text-center">
                                                            <Placeholder  animation="glow">
                                                                <Placeholder xs={2} />
                                                            </Placeholder>
                                                            </td>  
                                                        ))}  
                                                    </tr>         
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {(!loading)?
                            <TablePagination    
                                key={total}
                                totalCount={total}
                                pageSize={pageSize}
                                changeItemsPerPage={page => setPageSize(page)}
                                onPageChange={page => setCurrentPage(page)}
                                currentPage={currentPage}
                            />
                            :''
                            }
                            </>:      
                            <Table style={{overflow:"auto",fontSize:"0.7em"}} variant={(sales['succsess'])?"light":"danger"} responsive="md"  bordered hover>
                                <thead>
                                    {(!loading)?
                                        <tr>
                                            <th key={Math.random()}>{sales}</th>
                                        </tr>
                                    :
                                        <tr>
                                            <th className="text-center" key={Math.random()}>
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                        </tr>
                                    }
                                </thead>
                             </Table>
                        }
                    </Col>
                    {/* <Col md={4}>
                        <Calendar/>
                    </Col> */}
                    
                </Row>
                <Row>
                    
                </Row>
                <Row>  
                </Row>
                <Row>
                </Row>
                <WidthModal show={modal.show} onHide={() => setModal({show:false})} header={modal.header} body={modal.body}/>
            </>:
           navigate(LOGIN_ROUTE)
            }
        </Container>
        </>
        // <></>
    // }
    );
});


export default Sale4; 