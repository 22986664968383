import React, { useEffect,useState } from 'react';
import { Modal, Button, Form, InputGroup,FloatingLabel,Badge,OverlayTrigger,Popover  } from 'react-bootstrap';
import { createTag } from '../../http/CourseAPI';
import ShowToast from "../modals/ShowToast"
import { itManager, itSettings, setings_save } from "../../http/userAPI";

const CreateTag = ({show, onHide}) => {
    const [value, setValue] = useState([])
    const [toast, setToast] = useState({show:false, header:'', body:'', bgg:'danger'});
    const [trig, setTrig] = useState(true)
    const [addB, setAddB] = useState(false)
    const [manager, setManager] = useState([])
    const [statuses, setStatuses] = useState([
        {
            name:"Не выбрано",
            id:"zero",
            ruls:[],
            use:false
        },
        {
            name:"Неразобранные",
            id:32600683,
            ruls:[
                {
                    name:"Не выбрано",
                    id:"zero",
                    use:false
                },
                {
                    name:"Все",
                    id:"all",
                    use:false
                }
            ],
            use:false
        },
        {
            name:"Новый лид",
            id:32600686,
            ruls:[
                {
                    name:"Не выбрано",
                    id:"zero",
                    use:false
                },
                 {
                    name:"Все",
                    id:"all",
                    use:false
                }
            ],
            use:false
        },
        {
            name:"Сайт",
            id:'site',
            ruls:[
                {
                    name:"Не выбрано",
                    id:"zero",
                    use:false
                },
                {
                    name:"Telegram",
                    id:"telegram",
                    use:false
                },
                {
                    name:"WhatsApp",
                    id:"whatsapp",
                    use:false
                }
            ],
            use:false
        }
    ])

    useEffect(() => {
        // if (!show) return
        itSettings().then((data) => {
           if (data.error){
                setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
           } else {
                // setToast({show:true, header:"Успешно!!!", body:"Настройки загружены!!", bgg:'success'})
                console.log(data.success)
                setValue(data.success);
                setTrig(!trig)   
                //  setValue(JSON.parse(data.success));

                    


                console.log(value)
                console.log(data.success)

           }
          
        });
        itManager().then((data) => {
            if (data.error){
                 setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
            } else {
                //  setToast({show:true, header:"Успешно!!!", body:"Пользователи получены!!", bgg:'success'})
                 setManager(data.success)     
                 setTrig(!trig)   
            }
           
         });
        }, [show]);
  


    const addTag = () => {
        try {           
            let s = value.filter(element => element != null)
            setValue(s)
            setings_save(s).then((data) => {
                if (data.error){
                     setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
                } else {
                     setToast({show:true, header:"Успешно!!!", body:"Настройки сохранены!!", bgg:'success'})
                }
               
             });
            setToast({show:true, header:"Ошибка!!!", body:"Невалидный EMAIL!"})
                // onHide()      
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }


    const addCondition = () => {
        try {
            let v=value;
            v.push({
                condName:'Новое правило',
                condStatus:"zero",
                condUsl:"zero",
                condActive:false,
                condManager:{},
            })

            setValue(v)
            setTrig(!trig)    
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }
    
    const changeActive = (e) => {
        try {
            let v=value;
            v[e.target.attributes.ind.value].condActive=e.target.checked
           
           let j=prov()
           if (!j){
            
           v[e.target.attributes.ind.value].condActive=!e.target.checked
           e.target.checked =false
           setToast({show:true, header:"Ошиб4ка!!!", body:'Правило с такими настройками уже активировано!'})
           
           }

            setValue(v)
            prov()
           
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
      
       
    }

    const changeStatus = (e) => {
        try {
            let v=value;
            v[e.target.attributes.ind.value].condStatus=e.target.value
            v[e.target.attributes.ind.value].condUsl="zero"
            setValue(v)
            prov()
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }

    const changeRuls = (e) => {
        try {
            let v=value;
            v[e.target.attributes.ind.value].condUsl=e.target.value
            setValue(v)
            prov()
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }

    const delCondition = (e) => {
        try {
            let v=value;
            delete(v[e.target.attributes.ind.value])
            setValue(v)
            prov()
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }
    const changeManager = (e) => {
        try {
            // let v=value;
            // delete(v[e.target.attributes.ind.value])
            // setValue(v)
            // prov()
            console.log(e.target.attributes.manager_id.value)
            console.log(e.target.checked)
            console.log(e.target.attributes.group_id.value)
            let v=value;
            let group=e.target.attributes.group_id.value
            if (!v[e.target.attributes.ind.value].group){
                v[e.target.attributes.ind.value].group=group
            }
            if(e.target.checked){
               let vv=-1
               if (!v[e.target.attributes.ind.value].condManager[group]){
                v[e.target.attributes.ind.value].condManager[group]={}
               }
               for (let vvv in v[e.target.attributes.ind.value].condManager[group]){
                if (vv==-1){
                    vv = v[e.target.attributes.ind.value].condManager[group][vvv]
                } else if (v[e.target.attributes.ind.value].condManager[group][vvv]<vv){
                    vv = v[e.target.attributes.ind.value].condManager[group][vvv]
                }
               }
               if (vv===-1) 
               v[e.target.attributes.ind.value].condManager[group][e.target.attributes.manager_id.value]=0
               else
                v[e.target.attributes.ind.value].condManager[group][e.target.attributes.manager_id.value]=vv

            } else{
                        delete(v[e.target.attributes.ind.value].condManager[group][e.target.attributes.manager_id.value])
            }
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }

    const changeName = (e) => {
        try {
            let v=value;
            v[e.target.attributes.ind.value].condName=e.target.value
            setValue(v)
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }

    const prov = () => {
        try {
            statuses.map((status,sindex) => (
                status.ruls.map((rul2,rindex) => (                                         
                    rul2.use=false,
                    status.use=false
                ))
             ))
             let rrr=1
                for (let stat in statuses){
                    
                   if (statuses[stat].id=='zero') continue

                    let rr=1
                    for (let rul in statuses[stat].ruls){
                        if (statuses[stat].ruls[rul].id=='zero') continue
                        
                        for (let r in value){
                            // rrrr=0
                            if(value[r].condActive && value[r].condUsl==statuses[stat].ruls[rul].id && value[r].condStatus==statuses[stat].id){
                                if   (statuses[stat].ruls[rul].use==true) return false
                                statuses[stat].ruls[rul].use=true  
                                rr++
                            }
                        } 
                        
                    }
                    console.log(rr)
                    if (statuses[stat].ruls.length<=rr) {
                        statuses[stat].use=true
                        rrr++
                    } 
                    if (statuses[stat].ruls.length<rr) { 
                        return false       
                    } 
                    
                   
                   
                }
                if (statuses.length==rrr) 
                    setAddB(true) 
                else  
                    setAddB(false)

                

                return true
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }

    const getManName = (id) => {
        if (!id) return ''
        let n=''
        for (const element of manager) {
            console.log(element.id)
            console.log(id)
                if (element.id==id) {
                    console.log(element.name)
                    n=element.name
                    break
                }
            }
          
       return ('«'+n+'»')
        }
  


    return (
        <>
        {console.log('render')}
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
           Распределение менеджеров («Привлечение IT»)
            </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
            <Form>
              {  console.log(value)}
            {value.map((rul,index) => (
                <div style={{border:"1px solid", margin:"5px", borderRadius:"6px", padding:"5px"}}  key={"d"+index+'dd'}>
                     
                     <OverlayTrigger
                        trigger="click"
                        key={'ov_'+index}
                        placement="bottom"
                        overlay={
                            <Popover id={`popover-positioned-${'ov_'+index}`}>
                            <Popover.Header as="h3">Последние 5 событий:</Popover.Header>
                            <Popover.Body>
                                <ul>
                            {(rul.history)?rul.history.map((h,i) => (
                               <li  key={'ovv_'+i}>{h.split('*')[0]+getManName(h.split('*')[1])+(h.split('*')[2] || '')}</li>

                            )):'События отсутствуют'}
                                </ul>
                            </Popover.Body>
                            </Popover>
                        }
                        
                        >
                        <Badge style={{float:"right", cursor:"pointer"}} bg="dark">❓</Badge>
                        
                    </OverlayTrigger>
                    <InputGroup  className="mb-1" key={"d"+index+"ddds"}>
                        <InputGroup.Checkbox 
                            key={"d"+index+"dds"}
                            test={"d"+rul.condActive}
                            id={"d"+index+"dd"+rul.condActive}
                            onChange={changeActive}
                            defaultChecked={rul.condActive}
                            ind={index}
                            aria-label="Активность"
                            title="Активность" />
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Наименование"
                            // className="mb-1"
                        >
                        <Form.Control onChange={changeName} disabled={!rul.condActive}  ind={index} defaultValue={rul.condName} aria-label="Наименование" title="Наименование" />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput" 
                            label="Тип"
                            // className="mb-3" 
                        >
                            <Form.Select onChange={changeStatus} defaultValue={rul.condStatus} disabled={!rul.condActive} aria-label="Default select example"  ind={index} >
                                {/* <option  key='sel-1' disabled >Не выбрано</option> */}
                                    {statuses.map((status,sindex) => (
                                        
                                        <option value={status.id} disabled={(status.use && rul.id!=rul.condStatus)?true:false} key={"sel"+sindex}>{status.name}</option>
                                       
                                     ))}
                            
                            
                            </Form.Select>
                        </FloatingLabel>
                       { (rul.condStatus!='zero')?
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Условие"
                            // className="mb-1"
                        >
                            
                            <Form.Select onChange={changeRuls} defaultValue={rul.condUsl}   disabled={!rul.condActive} aria-label="Default select example"  ind={index} >
                            {statuses.map((status,sindex) => (
                                        status.ruls.map((rul2,rindex) => (                                         
                                        (status.id==rul.condStatus)
                                            ?
                                              <option  disabled={(rul2.use && rul2.id!=rul.condUsl)?true:false} usl={rul.condUsl} kk={status.id} kk2={rul.condStatus} value={rul2.id}  key={"sel2"+sindex+rindex}>{rul2.name}</option>
                                            
                                            :
                                              ''
                                        ))
                                     ))}
                            
                            
                            </Form.Select>
                        </FloatingLabel>
                        :''}
                        <Button onClick={delCondition}  ind={index}  variant="danger">Удалить</Button>
                    </InputGroup>
                    {

                    
                    manager.map((user,uindex) => (
                            <Form.Check 
                                key={uindex+'_'+user.id}
                                type="switch"
                                id={uindex+'_'+user.id}
                                label={user.name+' ['+user.id+']'}
                                disabled={(rul.condActive && rul.condStatus!='zero' && rul.condUsl!='zero')?false:true}
                                disabled2={(rul.condActive && rul.condStatus!='zero' && rul.condUsl!='zero')?'true':'false'}
                                manager_id={user.id}
                                group_id={user.group}
                                onChange={changeManager}
                                ind={index}
                                defaultChecked={(typeof rul.condManager[user.group] !== "undefined" && typeof rul.condManager[user.group][user.id] !== "undefined")?true:false}
                            />
                    ))
                    }
                  

                     <br/>
                     </div>
                ))}
               
            <hr/>
            {(!addB)?
                <Button onClick={addCondition} variant="success">Добавить правило</Button>
            :
                ''}
            {/* <hr/>
              <code>{JSON.stringify(value)}</code>
              <hr/>
              <code>{JSON.stringify(statuses)}</code> */}
            </Form>

        </Modal.Body>
        
        <Modal.Footer>
            <Button variant="outline-success" onClick={addTag}>Сохранить</Button>
            <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        </Modal.Footer>
        </Modal>
        <ShowToast show={toast.show} onClose={() => setToast({show:false})} header={toast.header} body={toast.body} bgg={toast.bgg}/>
        </>
    );
};


export default CreateTag;