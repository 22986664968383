import React, {createContext} from 'react'
// import ReactDOM from 'react-dom'
// import ReactDOM from 'react-dom/client';


import { createRoot } from 'react-dom/client';
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
// import './index.css'
import App from './App.js'
import UserStore from './store/UserStore.js';
import SalesStore from './store/SalesStore.js';

export const Context = createContext(null)


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(  <React.StrictMode>
    {/* <BrowserRouter> */}
    <Context.Provider value={{
        user: new UserStore(),
        sales: new SalesStore()
    }}>
        <App />
    </Context.Provider>  
    {/* </BrowserRouter> */}
</React.StrictMode>);