import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import ShowToast from "./ShowToast"
import { GetListOfBanks, UpdateBank, RemoveBank, AddBank } from "../../http/bankAPI";

const CreateTagBanks = ({ show, onHide }) => {
  const [toast, setToast] = useState({ show: false, header: '', body: '', bgg: 'danger' });
  const [trig, setTrig] = useState(true);
  const [banks, setBanks] = useState([]);
  const [changeList, setChangeList] = useState({ oldName: '', newName: '' });
  const [inputList, setInputList] = useState('-1');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!show) return
    GetListOfBanks().then((data) => {
      if (data.error) {
        setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'warning' })
      } else {
        setBanks(data.success)
      }
      // console.log('GET_LIST ' + banks);
    });
  }, [trig, show]);

  const editBank = (oldName, newName) => async () => {
    try {
      //let { oldName, newName } = changeList;
      UpdateBank(oldName, newName).then((data) => {
        if (data.error) {
          setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'warning' })
        } else {
          setChangeList({ oldName: '', newName: '' });
          setToast({ show: true, header: "Успешно!!!", body: "Изменения сохранены!!", bgg: 'success' })
        }
        setTrig(!trig)
      });
    } catch (error) {
      setToast({ show: true, header: "Ошибка!!!", body: error.response.data.message })
    }
  }

  const deleteBank = deleteName => async () => {
    try {
      setShowPopup(false);
      //let deleteName = changeList.oldName;
      RemoveBank(deleteName).then((data) => {
        if (data.error) {
          setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'warning' })
        } else {
          setChangeList({ oldName: '', newName: '' });
          setToast({ show: true, header: "Успешно!!!", body: "Изменения сохранены!!", bgg: 'success' });
        }
        setTrig(!trig)
      });
    } catch (error) {
      setToast({ show: true, header: "Ошибка!!!", body: error.response.data.message })
    }
  }

  const addBank = newName => async () => {
    try {
      if (newName == '') setToast({ show: true, header: "Введите название банка!!!" })
      else {
        AddBank(newName).then((data) => {
          if (data.error) {
            setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'warning' })
          } else {
            setToast({ show: true, header: "Успешно!!!", body: "Изменения сохранены!!", bgg: 'success' });
            // setBanks(data.success);
            // setChangeList('');
            setInputList('-1'); //Скрываем поле инпут для добавления банка
            // console.log('ADD ' + JSON.stringify(banks));
          }
          setTrig(!trig)
        });
      }
    } catch (error) {
      setToast({ show: true, header: "Ошибка!!!", body: error.response.data.message })
    }
  }
  return (
    <>
      {/* {console.log('render')} */}
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Настройка списка банков
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Modal show={showPopup} onHide={() => setShowPopup(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Внимание</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы точно хотите удалить банк?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowPopup(false)}>
                Нет
              </Button>
              <Button variant="secondary" onClick={deleteBank(changeList.oldName)}>
                Да
              </Button>
            </Modal.Footer>

          </Modal>
          {(inputList == '-1') ?
            <button
              onClick={() => { setInputList(''); setChangeList({ oldName:'', newName: '' }); }}
              className='m-1 btn btn-outline-primary btn-sm'
              variant="success">Добавить банк</button>
            : 
            <>
              <div className='banks'>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-group">
                      <input
                        onChange={e =>{ setInputList(e.target.value); }}
                        className="form-control form-control-sm"
                        defaultValue={inputList}
                        type='text' />
                      <span className="input-group-btn">
                        <button
                          onClick={addBank(inputList)}
                          className='ms-1 btn btn-outline-success btn-sm'>Сохранить</button>
                        <button
                          onClick={() => { setInputList('-1'); }}
                          className='ms-1 btn btn-outline-danger btn-sm'><i className="bi bi-trash3-fill"></i></button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
         }
          <hr />

          <Table style={{ display: "block", width: "100%", height:'400px',overflow: "auto", fontSize: "0.7em" }} className="table">
            <thead>
              <tr>
                <th className="text-center">
                  #
                </th>
                <th className="text-center">
                  Название банка
                </th>
                <th className="text-center">
                </th>
              </tr>
            </thead>
            <tbody>
              {
                (banks !== 0) ?
                  banks.map((banks, index) => (
                    < tr key={'tb_b_' + index}>
                      <td className="align-middle">
                        {index + 1}
                      </td>
                      <td className="align-middle">
                        <input
                          onChange={e => { setChangeList({ oldName: banks.bankname, newName: e.target.value }); setInputList('-1') }}
                          className="form-control form-control-sm"
                          key={banks.bankname}
                          value={(banks.bankname == changeList.oldName) ? changeList.newName:  banks.bankname}
                          placeholder="Название банка"
                          // onClick={e => { setDisabled(true); setCheck(e.target.defaultValue); setChangeList({ oldName: e.target.defaultValue, newName: e.target.value }); }}
                          disabled={(banks.bankname != changeList.oldName && changeList.oldName!='') ? true : false}
                          // onBlur={(e) => setDisabled(false)}
                          type="text"
                        />
                      </td>
                      <td className="align-middle">
                        
                          <button
                            hidden={(banks.bankname != changeList.oldName) ? true : false}
                            onClick={editBank(changeList.oldName, changeList.newName)}
                            type="button"
                            className="m-1 btn btn-outline-success btn-sm"><i className="bi bi-plus-square"></i>
                          </button> 

                          <button
                            hidden={(banks.bankname != changeList.oldName) ? true : false}
                            onClick={e => { setChangeList({ oldName: '', newName: '' }); }}
                            type="button"
                            className="m-1 btn btn-outline-danger btn-sm"><i className="bi bi-arrow-counterclockwise"></i>
                          </button> 
                        
                        <button type="button"
                          hidden={(changeList.oldName!='') ? true : false}
                          onClick={e => { setChangeList({ oldName: banks.bankname, newName: e.target.value }); setShowPopup(true) }}
                          className="m-1 btn btn-outline-danger btn-sm"><i className="bi bi-trash3-fill"></i></button>
                      </td>
                    </tr>
                  )) : ''
              }
            </tbody>
          </Table>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        </Modal.Footer>
      </Modal >
      <ShowToast show={toast.show} onClose={() => setToast({ show: false })} header={toast.header} body={toast.body} bgg={toast.bgg} />
    </>
  );
};


export default CreateTagBanks;