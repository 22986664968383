import { observer } from "mobx-react-lite";
import React , { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Placeholder, Row, Table } from "react-bootstrap";

import { Context } from "../index";
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import { INDEX_ROUTE, LOGIN_ROUTE } from "../utils/consts";
import Calendar from "../components/Calendar";
import { fetchSale1, fetchSale2} from "../http/salesAPI";
import WidthModal from "../components/modals/WidthModal"

const Sale2 = observer(() => {

    const location = useLocation()

    let s_d=''
    let e_d=''
    try {
        if (location.search.split('?')[1].split('&')[0].split('=')[0]==='startdate'){
            s_d=location.search.split('?')[1].split('&')[0].split('=')[1]
        }
        if (location.search.split('?')[1].split('&')[1].split('=')[0]==='enddate'){
            e_d=location.search.split('?')[1].split('&')[1].split('=')[1]
        }
    } catch (error) {
        //console.error(error)
    }
    
    const click = async () =>{

    //    if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) { 
    //        // alert("Не валидный EMAIL!")
    //        setToast({show:true, header:"Ошибка!!!", body:"Невалидный EMAIL!"})
    //        return false
    //     }

       try {
           setTrigger(!trigger)
           setLoading(true)
       } catch (error) {
        //    setToast({show:true, header:"Ошибка!!!", body:error.response.data.message})
            console.error(error)
       }
      
       
   }

    const {user} = useContext(Context)
    const navigate = useNavigate()

    const { sales } = useContext(Context);

    const [modal, setModal] = useState({show:false, header:'', body:''});
  

    let date = new Date()
   // http://localhost:3000/sale1?startdate=2022-08-12&enddate=2022-08-12
    if (s_d==='' || e_d===''){
        // if (date.getDate()===1){
            // s_d=date.getFullYear()+'-'+(('0' + date.getMonth()).slice(-2))+'-'+(('0' + (date.getDate()-1)).slice(-2))
        s_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + date.getDate()).slice(-2))
        // } else{
            // s_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + (date.getDate()-1)).slice(-2))
        // }
        
        e_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + date.getDate()).slice(-2))
    } else {

    }

    const [startDate, setStartDate] = useState(s_d)
    const [endDate, setEndDate] = useState(e_d)
    
    const [loading, setLoading] = useState(true)
    const [trigger, setTrigger] = useState(true)

    useEffect(() => {
        location.search="?startdate="+startDate+"=&enddate="+endDate
        navigate(location)
        fetchSale2(startDate,endDate).then((data) => {
            if(data.succsess){
                sales.setSale1(Object.keys(data).map(function(_) { return data[_]; }));
            } else {
                sales.setSale1(data.error);
            }
           
            
            // console.log(Object.keys(data).map(function(_) { return data[_]; }))
            

            // console.warn( location.search)
            setLoading(false)
        });
        }, [trigger]);

    return ( 
        <>
        <Container className="pt-2" style={{marginBottom:"70px"}}>
        <Breadcrumb>
        <NavLink className="breadcrumb-item" to={INDEX_ROUTE}>Главная</NavLink>
        {/* <Breadcrumb.Item href={SALE1_ROUTE}>
          Library
        </Breadcrumb.Item> */}
        
        <Breadcrumb.Item active>Отчет «Привлечение партнеров IT»</Breadcrumb.Item>
      </Breadcrumb>
             {user.isAuth?
             <>
                <Row>
                    <Col md={8}>
                    <Form className="row">
                    <Form.Group as={Col} xs="6" md="4" className="mt-3" controlId="startDate">
                    <Form.Label>Начальная дата:</Form.Label>
                    <Form.Control
                        // placeholder="Введите ваш email..."
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        type="date"
                        name="startDate"
                        disabled={loading}
                        // id="startDate"
                        // aria-describedby="loginHelp"
                    />    
                    </Form.Group>
                    <Form.Group as={Col} xs="6" md="4" className="mt-3" controlId="endDate">
                    <Form.Label>Конечная дата:</Form.Label>
                    <Form.Control
                        // placeholder="Дата заверще"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        type="date"
                        name="endDate"
                        disabled={loading}
                        // id="endDate"
                        // aria-describedby="loginHelp"
                    />    
                    </Form.Group>
                    <Form.Group as={Col} xs="12" md="4" className="text-center mt-5 " controlId="get">
                    <Button  onClick={click} disabled={loading} variant="success" className="">Посчитать</Button>
                    </Form.Group>
                    </Form>
                        <hr/>
                        <Table variant={(sales.sale1[0]!==undefined && sales.sale1[0].rows!==undefined)?"light":"danger"} responsive="md" striped bordered hover>
                            {
                           (sales.sale1[0]!==undefined && sales.sale1[0].rows!==undefined)?
                             <>
                             <thead>
                             <tr className="table-dark">
                                 <th rowSpan="2" title="Менеджер" className="text-center">Менеджер</th>
                                 <th colSpan="5" title="Новые лиды" className="text-center">Воронки</th>

                                 <th  colSpan="2" title="Входящие" className="text-center">Звонки</th>
                             </tr>
                             <tr className="table-dark">
                                 <th title="Новые лиды" className="text-center">Нов-й лид</th>
                                 <th title="Переведено в статус СОБИРАЮТ ДОКУМЕНТЫ" className="text-center">Соб-т док-ы</th>
                                 <th title="Переведено в статус ДОГОВОР ЗАКЛЮЧЕН" className="text-center">Дог-р зак-н</th>
                                 <th title="Партнер выдал первый кредит" className="text-center">1 кр-т</th>
                                 <th title="Отказ от сотрудничества" className="text-center">Отказ</th>

                                 <th title="Входящие" className="text-center">Вх</th>
                                 <th title="Исходящие" className="text-center">Исх</th>
                             </tr>




                         </thead>
                         <tbody>
                              {sales.sale1.map((item,index) => (
                                 Object.values(item.rows).map((item2,index2) => (
                                 <tr
                                     key={"dr2"+index2}
                                 >
                                    {!loading?
                                        <>
                                            <td className="text-center">{item2.name}</td>
                                            <td className="text-center">{item2.Новый.count}</td>   
                                            <td className="text-center">{item2.Документы.count}</td>   
                                            <td className="text-center">{item2.Заключен.count}</td>   
                                            <td className="text-center">{item2.Выдан.count}</td>   
                                            <td className="text-center">{item2.Отказ.count}</td>   
                                            <td className="text-center">{item2.zvonki.vh}</td>
                                            <td className="text-center">{item2.zvonki.ish}</td>
                                        </>  
                                        :   
                                        <>
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>   
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>   
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>   
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>   
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>   
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>   
                                            <td className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </td>   
                                        </>    
                                    }                            
                                 </tr> 
                                  ))
                                 ))} 
                         </tbody>
                         <tfoot>
                              {sales.sale1.map((item,index) => (
                                 <tr
                                    className="table-dark"
                                     key={"dr"+index}
                                 >
                                    {!loading?
                                        <>
                                            <th style={{color:'red'}} className="text-center">Итого:</th>
                                            <th style={{color:'red'}} className="text-center">{item.Новый}</th>
                                            <th style={{color:'red'}} className="text-center">{item.Документы}</th>
                                            <th style={{color:'red'}} className="text-center">{item.Заключен}</th>
                                            <th style={{color:'red'}} className="text-center">{item.Выдан}</th>
                                            <th style={{color:'red'}} className="text-center">{item.Отказ}</th>
                                            <th style={{color:'red'}} className="text-center">{item.vh}</th>
                                            <th style={{color:'red'}} className="text-center">{item.ish}</th>
                                            

                                        </>
                                        :
                                        <>
                                            <th style={{color:'red'}} className="text-center">Итого:</th>
                                            <th style={{color:'red'}} className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                            <th style={{color:'red'}} className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                            <th style={{color:'red'}} className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                            <th style={{color:'red'}} className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                            <th style={{color:'red'}} className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                            <th style={{color:'red'}} className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                            <th style={{color:'red'}} className="text-center">
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                        </>
                                    }
                                 </tr> 
                                 ))} 
                         </tfoot>
                         </>:
                          <thead>
                          <tr>
                              <th>{sales.sale1}</th>
                              
                          </tr>
                      </thead>
                            }
                            
                            
                        </Table>
                        
                    </Col>
                    <Col md={4}>
                        <Calendar/>
                    </Col>
                    
                </Row>
                <Row>
                    
                </Row>
                <Row>  
                </Row>
                <Row>
                </Row>
                <WidthModal show={modal.show} onHide={() => setModal({show:false})} header={modal.header} body={modal.body}/>
            </>:
           navigate(LOGIN_ROUTE)
            }
        </Container>
        </>
        // <></>
    // }
    );
});


export default Sale2;