import axios from 'axios';

const $host = axios.create({
    baseURL:process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
    baseURL:process.env.REACT_APP_API_URL
})

const $host_test = axios.create({
    baseURL:process.env.REACT_APP_API_URL_TEST
})

const $authHost_test = axios.create({
    baseURL:process.env.REACT_APP_API_URL_TEST
})

const $crmhost = axios.create({
    baseURL:process.env.REACT_APP_CRM_API_URL
})

const $akihost = axios.create({
    baseURL:process.env.REACT_APP_API_URL_AK_I
})


// const $crmauthHost = axios.create({
//     baseURL:process.env.REACT_APP_CRM_API_URL
// })

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)
$authHost_test.interceptors.request.use(authInterceptor)
$crmhost.interceptors.request.use(authInterceptor)
// $akihost.interceptors.request.use(authInterceptor)
// $crmauthHost.interceptors.request.use(authInterceptor)

export {
    $host,
    $authHost,
    $crmhost,
    $host_test,
    $authHost_test,
    $akihost
    // $crmauthHost
}