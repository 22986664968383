import Admin from "./pages/Admin"
import Sale1 from "./pages/Sale1"
import Sale2 from "./pages/Sale2"
import Sale4 from "./pages/Sale4"
import Sale6 from "./pages/Sale6"
import Sale7 from "./pages/Sale7"
import Up from "./pages/Up"
import Auth from "./pages/Auth"
import Index from "./pages/Index"
// import CoursePage from "./pages/CoursePage"
import NotFound from "./pages/NotFound"
import { ADMIN_ROUTE, INDEX_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE, SALE1_ROUTE, SALE2_ROUTE , SALE4_ROUTE, SALE6_ROUTE, SALE7_ROUTE,UP_ROUTE } from "./utils/consts"

export const adminRoutes = [
    {
        path: INDEX_ROUTE,
        // Component: <Auth/>
        Component: <Index/> 
    },
    {
        path: ADMIN_ROUTE,
        Component: <Admin/>
    },
    {
        path: SALE1_ROUTE,
        Component: <Sale1/>
    },
    {
        path: SALE2_ROUTE,
        Component: <Sale2/>
    },
    {
        path: SALE4_ROUTE,
        Component: <Sale4/>
    },
    {
        path: SALE6_ROUTE,
        Component: <Sale6/>
    },
    {
        path: SALE7_ROUTE,
        Component: <Sale7/>
    },
    {
        path: UP_ROUTE,
        Component: <Up/>
    }
]

export const rukRoutes = [
    {
        path: INDEX_ROUTE,
        // Component: <Auth/>
        Component: <Index/>
    },
    // {
    //     path: ADMIN_ROUTE,
    //     Component: <Admin/>
    // },
    {
        path: SALE1_ROUTE,
        Component: <Sale1/>
    },
    {
        path: SALE2_ROUTE,
        Component: <Sale2/>
    },
    {
        path: SALE4_ROUTE,
        Component: <Sale4/>
    },
    {
        path: SALE6_ROUTE,
        Component: <Sale6/>
    },
    {
        path: SALE7_ROUTE,
        Component: <Sale7/>
    },
    {
        path: UP_ROUTE,
        Component: <Up/>
    }
]

export const rukRoutes2 = [
    {
        path: INDEX_ROUTE,
        // Component: <Auth/>
        Component: <Index/>
    },
    {
        path: ADMIN_ROUTE,
        Component: <Admin/>
    },
    {
        path: SALE1_ROUTE,
        Component: <Sale1/>
    },
    {
        path: SALE2_ROUTE,
        Component: <Sale2/>
    },
    // {
    //     path: SALE4_ROUTE,
    //     Component: <Sale4/>
    // },
    {
        path: SALE6_ROUTE,
        Component: <Sale6/>
    },
    // {
    //     path: SALE7_ROUTE,
    //     Component: <Sale7/>
    // },
    // {
    //     path: UP_ROUTE,
    //     Component: <Up/>
    // }
]

// export const authRoutes = [
//     {
//         path: ADMIN_ROUTE,
//         Component: <Admin/>
//     }
// ]

export const publicRoutes = [
    // {
    //     path: INDEX_ROUTE,
    //     // Component: <Auth/>
    //     Component: <Index/>
    // },
      {  
         path: INDEX_ROUTE,
         // Component: <Auth/>
         Component: <Auth/>
     },
    {
        path: LOGIN_ROUTE,
        Component: <Auth/>
    },
    {
        path: REGISTRATION_ROUTE,
        Component: <Auth/>
    },
    // {
    //     path: COURSE_ROUTE + '/:id',
    //     Component: <CoursePage/>
    // },
    {
        path: '*',
        Component: <Auth/>
    }
]

export const managerRoutes = [
    // {
    //     path: INDEX_ROUTE,
    //     // Component: <Auth/>
    //     Component: <Index/>
    // },
      {  
         path: INDEX_ROUTE,
         // Component: <Auth/>
         Component: <Index/>
     },
    {
        path: UP_ROUTE,
        Component: <Up/>
    },
    // {
    //     path: COURSE_ROUTE + '/:id',
    //     Component: <CoursePage/>
    // },
    {
        path: '*',
        Component: <Auth/>
    }
]