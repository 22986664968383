import React , { useContext, useEffect, useState  } from "react";
// import { Context } from "../index";

import { NavLink } from "react-router-dom";
import {observer} from 'mobx-react-lite'
import { Badge, ListGroup, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { Context } from "..";
import { fetchPersons } from "../http/userAPI";



const Calendar = observer(() => {
    const [myDate, setMyDate] = useState(new Date(new Date().getFullYear(),new Date().getMonth()))

    const { user } = useContext(Context);
    useEffect(() => {
    fetchPersons(myDate.getMonth()+1).then((data) => {
        user.setPersons(data.rows);
        // persons.setTotalCount(data.count);
    });
    }, [myDate]);

    const getDay = (date) => {
       
        let day = date.getDay();
        if (day === 0) day = 7; // сделать воскресенье (0) последним днем
       
        return day - 1;
    }

    const getBirthDay = (date) => {
       
        let day = date.getDate();
        let month = date.getMonth()+1;
        let rez = false
        user.persons.forEach((person) => {
            let bd = new Date(person.birthday).getDate()
            let bm = new Date(person.birthday).getMonth()+1
            let by = new Date(person.birthday).getFullYear()
            if (bd===day && bm===month){
                rez =  <Popover key={'popover-basic-'+bd+'-'+bm} id={'popover-basic-'+bd+'-'+bm}>
                <Popover.Header as="h3">{bd+'.'+bm+'.'+by}</Popover.Header>
                <Popover.Body>
                  {person.surname+' '+person.name}<br/>
                  {person.position}
                </Popover.Body>
              </Popover>
                return true
            }
           
    })
        return rez
    }
    var d = new Date(myDate.getFullYear(), myDate.getMonth());

    var mounthName=[
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
     ];



    return(
        <>
            <Table variant="dark" responsive="md" striped bordered hover>
                <thead>
                    <tr>
                        <th className="text-center align-middle">
                            <NavLink onClick={() => setMyDate(new Date(myDate.getFullYear(),myDate.getMonth()-1))} className="" to="">
                                <i style={{fontWeight:800,fontSize:'18pt'}} className="bi bi-arrow-left-circle-fill"></i>
                            </NavLink>
                        </th>
                        <th className="text-center" colSpan={5}>
                            <h4>{mounthName[myDate.getMonth()]} {myDate.getFullYear()}</h4>
                        </th>
                        <th className="text-center align-middle">
                            <NavLink onClick={() => setMyDate(new Date(myDate.getFullYear(),myDate.getMonth()+1))} className="" to="">
                                <i style={{fontWeight:800,fontSize:'18pt'}} className="bi bi-arrow-right-circle-fill"></i>
                            </NavLink>
                        
                        </th>
                    </tr>
                    <tr>
                        <th className="text-center">ПН</th>
                        <th className="text-center">ВТ</th>
                        <th className="text-center">СР</th>
                        <th className="text-center">ЧТ</th>
                        <th className="text-center">ПТ</th>
                        <th className="text-center">СБ</th>
                        <th className="text-center">ВС</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    {
                        Array(getDay(myDate)).fill(1).map((el, i) =>
                            <td key={'dz'+i}></td>
                        )
                    }
                    {(
                        function (rows, i, len) {
                        while (i.getMonth() === len) {

                            if (getDay(d) % 7 === 6 || getDay(d) % 7 === 5) { // вс, последний день - перевод строки
                               if (getBirthDay(i)!==false){
                                    rows.push(<OverlayTrigger  key={'oo'+i.getDate()+'-'+i.getMonth()+1+'-'+i.getFullYear()}  overlay={getBirthDay(i)}><th style={{color:'orange'}} className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</th></OverlayTrigger>)
                               } else{
                                    rows.push(<th style={{color:'red'}} className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</th>)
                               }  
                            } else {
                                if (getBirthDay(i)!==false){
                                    rows.push(<OverlayTrigger  key={'oo'+i.getDate()+'-'+i.getMonth()+1+'-'+i.getFullYear()}  overlay={getBirthDay(i)}><th style={{color:'orange'}} className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</th></OverlayTrigger>)
                               } else{
                                    rows.push(<td className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</td>)
                               }
                               
                            }
                        
                            if (getDay(d) % 7 === 6) { // вс, последний день - перевод строки
                                i.setDate(i.getDate() + 1);
                                break
                            }
                            i.setDate(i.getDate() + 1);
                        }
                        return rows;
                    })([],d, myDate.getMonth())}
                    </tr>
                    {(
                        function (rows, cols, i, len) {
                        while (i.getMonth() === len) {
                            if (getDay(d) % 7 === 6 || getDay(d) % 7 === 5) { // вс, последний день - перевод строки
                                if (getBirthDay(i)!==false){
                                    cols.push(<OverlayTrigger  key={'oo'+i.getDate()+'-'+i.getMonth()+1+'-'+i.getFullYear()}  overlay={getBirthDay(i)}><th style={{color:'orange'}} className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</th></OverlayTrigger>)
                               }else {
                                    cols.push(<th style={{color:'red'}} className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</th>)
                                }
                               
                            } else {
                               if (getBirthDay(i)!==false){
                                    cols.push(<OverlayTrigger  key={'oo'+i.getDate()+'-'+i.getMonth()+1+'-'+i.getFullYear()}  overlay={getBirthDay(i)}><th  style={{color:'orange'}} className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</th></OverlayTrigger>)
                               }else {
                                    cols.push(<td className="text-center" key={'dd'+i.getDate()}>{i.getDate()}</td>)
                               }
                               
                            }
                        if (getDay(d) % 7 === 6) { // вс, последний день - перевод строки
                            rows.push(<tr key={'cdd'+i.getDate()}>{cols}</tr>)
                            cols=[]
                        }
                        i.setDate(i.getDate() + 1);
                        }
                        if (cols.length!==0){
                            cols.push(<td colSpan={7-cols.length} className="text-center" key={'dd'+i.getDate()}></td>)
                            rows.push(<tr key={'cdd'+i.getDate()}>{cols}</tr>)
                        }
                        return rows;
                    })([],[],d, myDate.getMonth())}
                </tbody>
            </Table> 
            
               <ListGroup  as="ol" numbered>
               {user.persons.map((person,index) => (
                <ListGroup.Item
                    key={"dr"+person.id}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{person.surname} {person.name}</div>
                            <b style={{color:'red'}}>
                                {new Date(person.birthday).getDate()}.{new Date(person.birthday).getMonth()+1}.{new Date(person.birthday).getFullYear()}
                            </b>
                        </div>
                    <Badge bg="primary" pill>
                    {(person.user.Citys[0])?person.user.Citys[0].description:''}
                    </Badge>
                </ListGroup.Item> 
                ))}
             </ListGroup>
           
        </>       
    );

});

export default Calendar;


