import React , { useContext } from "react";
import { Context } from "../index";
import { NavLink } from "react-router-dom";
import { ADMIN_ROUTE, INDEX_ROUTE, LOGIN_ROUTE } from "../utils/consts";
import {observer} from 'mobx-react-lite'
import {useNavigate } from "react-router-dom"

const MobNavBar = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        localStorage.removeItem('token')
        navigate(INDEX_ROUTE)
    }
    //console.log(user.user.role)
    return(
        <nav className="mobile-nav" style={{"zIndex":1}}>
            {/* <Container> */}
             
                {
                (user.isAuth && user.user.role==='ADMIN') ?
                    <>
                        {/* <Nav className="ml-auto mobile-nav"> */}
                            <NavLink  role="button" className="bloc-icon" to={INDEX_ROUTE}>
                                {/* Админ панель */}
                                <i className="bi bi-house-fill"></i>
                            </NavLink>
                            <NavLink  role="button" className="bloc-icon" to={ADMIN_ROUTE}>
                                {/* Админ панель */}
                                <i className="bi bi-gear"></i>
                            </NavLink>
                            <NavLink onClick={() => logOut()} className="bloc-icon" to="">
                                {/* Выйти */}
                                <i className="bi bi-door-open"></i>
                            </NavLink>
                        {/* </Nav> */}
                    </>
                    :(user.isAuth && user.user.role==='RUK+') ?
                    <>
                        {/* <Nav className="ml-auto mobile-nav"> */}
                            <NavLink  role="button" className="bloc-icon" to={INDEX_ROUTE}>
                                {/* Админ панель */}
                                <i className="bi bi-house-fill"></i>
                            </NavLink>
                            <NavLink  role="button" className="bloc-icon" to={ADMIN_ROUTE}>
                                {/* Админ панель */}
                                <i className="bi bi-gear"></i>
                            </NavLink>
                            <NavLink onClick={() => logOut()} className="bloc-icon" to="">
                                {/* Выйти */}
                                <i className="bi bi-door-open"></i>
                            </NavLink>
                        {/* </Nav> */}
                    </>
                    : (user.isAuth && user.user.role==='RUK') ?
                    <>
                    {/* <Nav className="ml-auto mobile-nav"> */}
                        <NavLink  role="button" className="bloc-icon" to={INDEX_ROUTE}>
                            {/* Админ панель */}
                            <i className="bi bi-house-fill"></i>
                        </NavLink>
                        <NavLink onClick={() => logOut()} className="bloc-icon" to="">
                            {/* Выйти */}
                            <i className="bi bi-door-open"></i>
                        </NavLink>
                    {/* </Nav> */}
                </>: (user.isAuth && user.user.role==='MAN') ?
                    <>
                    {/* <Nav className="ml-auto mobile-nav"> */}
                        <NavLink  role="button" className="bloc-icon" to={INDEX_ROUTE}>
                            {/* Админ панель */}
                            <i className="bi bi-house-fill"></i>
                        </NavLink>
                        <NavLink onClick={() => logOut()} className="bloc-icon" to="">
                            {/* Выйти */}
                            <i className="bi bi-door-open"></i>
                        </NavLink>
                    {/* </Nav> */}
                </>
                    :
                    <>
                    {/* <Nav className="ml-auto mobile-nav"> */}
                        <NavLink  role="button" className="bloc-icon" to={LOGIN_ROUTE}>
                            {/* Авторизация/Регистрация */}
                            <i className="bi bi-door-closed"></i>
                        </NavLink>
                        {/* <Nav.Link  onClick={() =>navigate(LOGIN_ROUTE)}>Авторизация/Регистрация</Nav.Link> */}
                    {/* </Nav> */}
                    </>
                }     
            {/* </Container> */}
        </nav >
    );

});

export default MobNavBar;