import React, { useEffect,useState } from 'react';
import { Modal, Button, Form, InputGroup,FloatingLabel,Table } from 'react-bootstrap';
import ShowToast from "./ShowToast"
import { get_citys, get_users, edit_citys, edit_role, changePassword } from "../../http/userAPI";

import { confirm } from "../confirm/Confirmation";

const CreateTagUsers = ({show, onHide}) => {
    const [toast, setToast] = useState({show:false, header:'', body:'', bgg:'danger'});
    const [trig, setTrig] = useState(true)
    const [users, setUsers] = useState([])
    const [citys, setSitys] = useState([])
    const [password, setPassword] = useState('')
    const [passwordUser, setPasswordUser] = useState('')

    useEffect(() => {
        if (!show) return
        get_users().then((data) => {
           
            if (data.error){
                 setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
            } else {
                //  setToast({show:true, header:"Успешно!!!", body:"Пользователи получены!!", bgg:'success'})
                setUsers(data.succsess.rows)     
                console.log(users)
                
            }
           
         });
        }, [trig,show]);
        useEffect(() => {
            
            // if (!show) return
       
            get_citys().then((data) => {
                if (data.error){
                     setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
                } else {
                    //  setToast({show:true, header:"Успешно!!!", body:"Пользователи получены!!", bgg:'success'})
                    setSitys(data.succsess.rows)     
                    console.log(citys)
                    //  setTrig(!trig)   
                }
               
             });
            }, [trig,show]);
  



    const handleChangeCity = (e) => {
        console.log(e.target.value);
        try {
            edit_citys(e.target.value.split('*')[0], e.target.value.split('*')[1]).then((data) => {
                if (data.error){
                     setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
                } else {
                     setToast({show:true, header:"Успешно!!!", body:"Настройки сохранены!!", bgg:'success'})
                     console.log(data)
                }
                setTrig(!trig)   
             });
        } catch (error) {
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
        
        

      }

      const changePasswordb = () => {
        try {
            changePassword(passwordUser, password).then((data) => {
                if (data.error){
                     setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
                } else {
                     setToast({show:true, header:"Успешно!!!", body:"Пароль изменен!!", bgg:'success'})
                     setPassword('')
                     setPasswordUser('')
                }
                // setTrig(!trig)   
             });
        } catch (error) {
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
        
        

      }

      async function handleChangeRole(e) {
            try {
                edit_role(e.target.value.split('*')[0], e.target.value.split('*')[1]).then((data) => {
                    if (data.error){
                            setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
                    } else {
                            setToast({show:true, header:"Успешно!!!", body:"Настройки сохранены!!", bgg:'success'})
                            console.log(data)
                    }
                    setTrig(!trig)   
                    });
            } catch (error) {
                setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
            }
      }
    return (
        <>
        {console.log('render')}
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
          Настройка пользователей
            </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <Table style={{display:"block",width:"100%", overflow:"auto",fontSize:"0.7em"}} variant="light" responsive="md"  bordered hover className="">
            <thead>
                <tr>
                    <th className="text-center">
                        #
                    </th>
                    <th className="text-center">
                        Фамилия
                    </th>
                    <th className="text-center">
                        Имя
                    </th>
                    <th className="text-center">
                        Должность
                    </th>
                    <th className="text-center">
                        Почта
                    </th>
                    <th className="text-center">
                        Пароль
                    </th>
                    <th className="text-center">
                        Телефон
                    </th>
                    <th className="text-center">
                        Роль
                    </th>
                    <th className="text-center">
                        Филиал
                    </th>
                </tr>
            </thead>
            <tbody>
              {  console.log(users)}
            {
            (users.length!=0)?
            users.map((user,uindex) => (
                <tr key={user.userId}>
                    <td   className="text-center">
                        {uindex+1}
                    </td>
                    <td className="text-center">
                     {user.surname}
                    </td>
                    <td className="text-center">
                     {user.name}
                    </td>
                    <td className="text-center">
                     {user.position}
                    </td>
                    <td className="text-center">
                     {user.user.email} 
                    </td>
                    <td className="text-center">
                        {(passwordUser==user.userId)
                        ?
                        <InputGroup  style={{fontSize:"1em", width:password.length+18+'ch',minWidth:'220px'}}>
                        
                        <Form.Control
                            style={{fontSize:"1em"}}
                            type="text"
                            placeholder="Новый пароль..."
                            aria-label="Новый пароль..."
                            aria-describedby="btnGroupAddon2"
                            onChange={e => setPassword(e.target.value)}
                           
                        />
                        <InputGroup.Text
                            id="btnGroupAddon1"
                            style={{cursor:"pointer"}}
                            onClick={e => {changePasswordb();}}
                        >✅</InputGroup.Text>
                        <InputGroup.Text
                            id="btnGroupAddon2" 
                            style={{cursor:"pointer"}}
                            onClick={e => {setPasswordUser(''); setPassword('');}}
                        >❌</InputGroup.Text>
                        </InputGroup>
                        :<a onClick={e => {setPasswordUser(user.userId); setPassword('');}} style={{cursor:'pointer'}}>🙈</a>
                        }
                     <a></a>
                    </td>
                    <td className="text-center">
                     {user.phone}
                    </td>
                    <td className="text-center">
                        {(user.user.role=="ADMIN")?
                    user.user.role:
                    <Form.Select
                        defaultValue={user.userId+"*"+user.user.role}
                        onChange={(e) => handleChangeRole(e)}
                        style={{ width: "100px",fontSize:"1em"}}
                    >   
                        <option key={user.userId+"_NEW"}  value={user.userId+"*"+"NEW"}>NEW</option>
                        <option key={user.userId+"_MAN"}  value={user.userId+"*"+"MAN"}>MAN</option>
                        <option key={user.userId+"_RUK"}  value={user.userId+"*"+"RUK"}>RUK</option>
                        <option key={user.userId+"_RUK+"}  value={user.userId+"*"+"RUK+"}>RUK+</option>
                        <option key={user.userId+"_ADMIN"}  value={user.userId+"*"+"ADMIN"}>ADMIN</option>
                    </Form.Select>
                    }
                    
                    </td>
                    <td className="text-center">
                    {(user.user.role=="ADMIN" || user.user.role=="NEW")?''
                    :
                    <Form.Select
                        disabled={(user.user.role=="ADMIN" || user.user.role=="NEW")?true:false}
                        defaultValue={(user.user.Citys[0])?user.userId+'*'+user.user.Citys[0].id:'-1'}
                        onChange={(e) => handleChangeCity(e)}
                        style={{ width: "200px",fontSize:"1em"}}
                    >   
                        <option key={user.userId+"_-1"} value={user.userId+"*-1"}>Не выбран</option>
                        {citys.map((city,cindex) => (
                            <option key={user.userId+"_"+city.id}  value={user.userId+"*"+city.id}>{city.description}</option>
                        ))}
                    </Form.Select>
                    }
                    </td>
    

                </tr>
        )):''
        }
               
            {/* <hr/>
              <code>{JSON.stringify(value)}</code> */}
              {/* <hr/>
              <code>{JSON.stringify(statuses)}</code> */}
              </tbody>
            </Table>

        </Modal.Body>
        
        <Modal.Footer>
            <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        </Modal.Footer>
        </Modal>
        <ShowToast show={toast.show} onClose={() => setToast({show:false})} header={toast.header} body={toast.body} bgg={toast.bgg}/>
        </>
    );
};


export default CreateTagUsers;