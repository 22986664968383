export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const INDEX_ROUTE = '/'
export const SALE1_ROUTE = '/sale1'
export const SALE2_ROUTE = '/sale2'
export const SALE4_ROUTE = '/sale4'
export const SALE6_ROUTE = '/sale6'
export const SALE7_ROUTE = '/sale7'
export const UP_ROUTE = '/up'
