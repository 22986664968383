import React, { useContext } from "react";
import { Context } from "../index";

// import Container from 'react-bootstrap/Container'
// import { NavLink } from "react-router-dom";
import {observer} from 'mobx-react-lite'
import { Card, Row } from "react-bootstrap";
import { SALE1_ROUTE, SALE2_ROUTE,SALE4_ROUTE,SALE6_ROUTE, SALE7_ROUTE, UP_ROUTE } from "../utils/consts";
import { NavLink } from "react-router-dom";
// import CustomLink2 from "./CustomLink2";

const IndexBar = observer(() => {
        const {user} = useContext(Context)
    // const {user} = useContext(Context)
    return(
        <Row>
            {/* <h1>Панель навигации:</h1> */}
            {/* <div className='row  justify-content-md-center'> */}
                {/* {categories.map(category => */}
                    {/* <div style={{'paddingBottom':'10px'}} key={category.id} className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12  list-group'> 
                        <CustomLink2 to={`category/${category.id}`}>{category.title}</CustomLink2>
                    </div> */}
                {/* )} */}
                {(user.isAuth && (user.user.role==='ADMIN'|| user.user.role==='RUK' || user.user.role==='RUK+') )?
                 <Card className="col-md-5 col-sm-5 m-1 myCard">
                         <NavLink  role="button" style={{color:'black'}} className="nav-link" to={SALE1_ROUTE}>
                                <Card.Body>
                                        <Card.Title>Отчет по развитию рег.сети</Card.Title>
                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                        <Card.Text>
                                        Данный отчет содержит информацию по заведению новых партнеров:
                                        </Card.Text>
                                        <ul>
                                            <li>Регион</li>    
                                            <li>Количество</li>
                                            <li>Правовая форма</li>
                                        </ul>
                                        
                                        {/* <Card.Link href={SALE1_ROUTE}>Перейти к отчету</Card.Link> */}
                                        {/* <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                        </NavLink>
                </Card>:''}
                {(user.isAuth && (user.user.role==='ADMIN'|| user.user.role==='RUK' || user.user.role==='RUK+') )?
                <Card className="col-md-5 col-sm-5 m-1 myCard">
                         <NavLink  role="button" style={{color:'black'}} className="nav-link" to={SALE2_ROUTE}>
                                <Card.Body>
                                        <Card.Title>Отчет «Привлечение партнеров IT»</Card.Title>
                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                        <Card.Text>
                                        Данный отчет содержит информацию по заведению новых партнеров по менеджерам:
                                        </Card.Text>
                                        <ul>
                                            <li>Менеджер</li>    
                                            <li>Новые лиды</li>
                                            <li>Переведено в статус СОБИРАЮТ ДОКУМЕНТЫ</li>
                                            <li>Переведено в статус ДОГОВОР ЗАКЛЮЧЕН</li>
                                            <li>Партнер выдал первый кредит</li>
                                            <li>Отказ от сотрудничества</li>

                                            <li>Входящие звонки</li>
                                            <li>Исходящие звонки</li>
                                        </ul>
                                        
                                        {/* <Card.Link href={SALE1_ROUTE}>Перейти к отчету</Card.Link> */}
                                        {/* <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                        </NavLink>
                </Card>:''}
                  {(user.isAuth && (user.user.role==='ADMIN'|| user.user.role==='RUK' || user.user.role==='RUK+') )?
                <Card className="col-md-5 col-sm-5 m-1 myCard">
                         <NavLink  role="button" style={{color:'black'}} className="nav-link" to={SALE6_ROUTE}>
                                <Card.Body>
                                        <Card.Title>Отчет «Активация партнеров сети»</Card.Title>
                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                        <Card.Text>
                                        Данный отчет содержит информацию по активации новых партнеров по регионам:
                                        </Card.Text>
                                        <ul>
                                            <li>Регион</li>    
                                            <li>Кол-во партнеров</li>
                                            <li>Активировались</li>
                                            <li>% активации</li>
                                        </ul>
                                        
                                        {/* <Card.Link href={SALE1_ROUTE}>Перейти к отчету</Card.Link> */}
                                        {/* <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                        </NavLink>
                </Card>:''}
                  {(user.isAuth && (user.user.role==='ADMIN'|| user.user.role==='RUK') )?
                <Card className="col-md-5 col-sm-5 m-1 myCard">
                         <NavLink  role="button" style={{color:'black'}} className="nav-link" to={SALE4_ROUTE}>
                                <Card.Body>
                                        <Card.Title>Аналитика</Card.Title>
                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                        <Card.Text>
                                       
                                        </Card.Text>                                        
                                        {/* <Card.Link href={SALE1_ROUTE}>Перейти к отчету</Card.Link> */}
                                        {/* <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                        </NavLink>
                </Card>:''}
                  {(user.isAuth && (user.user.role==='ADMIN'|| user.user.role==='RUK') )?
                <Card className="col-md-5 col-sm-5 m-1 myCard">
                         <NavLink  role="button" style={{color:'black'}} className="nav-link" to={SALE7_ROUTE}>
                                <Card.Body>
                                        <Card.Title>% отказов в разрезе по месяцам</Card.Title>
                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                        <Card.Text>
                                        Данный отчет содержит информацию по отказам в разрезе по месяцам:
                                        </Card.Text> 
                                        <ul>
                                                <li>Регион</li>    
                                                <li>% отказов по месяца</li>
                                        </ul>
                                                                               
                                        {/* <Card.Link href={SALE1_ROUTE}>Перейти к отчету</Card.Link> */}
                                        {/* <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                        </NavLink>
                </Card>:''
                }
                {(user.isAuth && (user.user.role==='ADMIN'|| user.user.role==='RUK' || user.user.role==='MAN') )?
                <Card className="col-md-5 col-sm-5 m-1 myCard">
                         <NavLink  role="button" style={{color:'black'}} className="nav-link" to={UP_ROUTE}>
                                <Card.Body>
                                        <Card.Title>Учет продаж</Card.Title>
                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                        <Card.Text>
                                        
                                        </Card.Text>                                        
                                        {/* <Card.Link href={SALE1_ROUTE}>Перейти к отчету</Card.Link> */}
                                        {/* <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                        </NavLink>
                </Card>
                :''
                }

        </Row>
        
    );

});

export default IndexBar;


