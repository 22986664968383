import {makeAutoObservable} from 'mobx';

export default class UserStore {
    constructor () {
        this._isAuth = false
        this._user = {}
        this._persons = []
        makeAutoObservable(this)
    }
    // SETTERS
    setIsAuth(bool){
        this._isAuth = bool
    }
    setUser(user){
        this._user = user
    }

    setPersons(persons){
        this._persons = persons
    }


    // GETTERS
    get isAuth(){
        return this._isAuth
    }
    get user(){
        return this._user
    }

    get persons(){
        return this._persons
    }


}