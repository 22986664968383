import React, { useEffect,useState } from 'react';
import { Modal, Button, Form, InputGroup,FloatingLabel } from 'react-bootstrap';
import { createTag } from '../../http/CourseAPI';
import ShowToast from "./ShowToast"
import { itManager, usersSettings, user_setings_save } from "../../http/userAPI";

const CreateTagUserLink = ({show, onHide}) => {
    const [value, setValue] = useState({})
    const [toast, setToast] = useState({show:false, header:'', body:'', bgg:'danger'});
    const [trig, setTrig] = useState(true)
    const [manager, setManager] = useState([])

    useEffect(() => {
        usersSettings().then((data) => {
           if (data.error){
                setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
           } else {
                // setToast({show:true, header:"Успешно!!!", body:"Настройки загружены!!", bgg:'success'})
                setValue(data.success);
                setTrig(!trig)   
           }
          
        });
        itManager().then((data) => {
            if (data.error){
                 setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
            } else {
                //  setToast({show:true, header:"Успешно!!!", body:"Пользователи получены!!", bgg:'success'})
                 setManager(data.success)     
                 setTrig(!trig)   
            }
           
         });
        }, [show]);
  


    const addTag = () => {
        try {           
            let s = value
            setValue(s)
            user_setings_save(s).then((data) => {
                if (data.error){
                     setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'warning'})
                } else {
                     setToast({show:true, header:"Успешно!!!", body:"Настройки сохранены!!", bgg:'success'})
                }
               
             });
            setToast({show:true, header:"Ошибка!!!", body:"Невалидный EMAIL!"})
                // onHide()      
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }


    const changeWhatsApp = (e) => {
        try {
            let v=value;
            if (!v[e.target.attributes.ind.value])  
                v[e.target.attributes.ind.value]={}
            v[e.target.attributes.ind.value].whatsapp=e.target.value
            setValue(v)
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }

    const changeTelegram = (e) => {
        try {
            let v=value;
            if (!v[e.target.attributes.ind.value])  
                v[e.target.attributes.ind.value]={}
            v[e.target.attributes.ind.value].telegram=e.target.value
            setValue(v)
            setTrig(!trig)            
        } catch(error){
            setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
        }
       
    }

    return (
        <>
        {console.log('render')}
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
          Связка данных пользователей
            </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
            <Form>
              {/* {  console.log(value)} */}
            {
            
            manager.map((user,uindex) => (
                <InputGroup  className="mb-1" key={"d"+uindex+"ddds"}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={user.name} 
                        // className="mb-1"
                    >
                        <Form.Control
                            disabled={true}
                            defaultValue={user.id} 
                            aria-label="Пользователь" 
                            title="Пользователь" 
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Whatsapp"
                        // className="mb-1"
                    >
                        <Form.Control
                            onChange={changeWhatsApp} 
                            defaultValue={(value[user.id])?value[user.id].whatsapp:''}
                            aria-label="Whatsapp" 
                            title="Whatsapp" 
                            ind={user.id}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Telegram"
                        // className="mb-1"
                    >
                        <Form.Control
                            onChange={changeTelegram} 
                            defaultValue={(value[user.id])?value[user.id].telegram:''}
                            aria-label="Telegram" 
                            title="Telegram" 
                            ind={user.id}
                        />
                    </FloatingLabel>
                </InputGroup>
        ))
        }
               
            {/* <hr/>
              <code>{JSON.stringify(value)}</code> */}
              {/* <hr/>
              <code>{JSON.stringify(statuses)}</code> */}
            </Form>

        </Modal.Body>
        
        <Modal.Footer>
            <Button variant="outline-success" onClick={addTag}>Сохранить</Button>
            <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        </Modal.Footer>
        </Modal>
        <ShowToast show={toast.show} onClose={() => setToast({show:false})} header={toast.header} body={toast.body} bgg={toast.bgg}/>
        </>
    );
};


export default CreateTagUserLink;