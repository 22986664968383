import React from 'react';
import { ToastContainer,  Toast } from 'react-bootstrap';

const ShowToast = ({show, onClose, header, body, bgg='danger'}) => {
    return (
      <ToastContainer style={{zIndex:99999}} className="p-3" position='top-end'>
        <Toast  bg={bgg} onClose={onClose} show={show} delay={5000} autohide>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">{header}</strong>
          {/* <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body>{body}</Toast.Body>
      </Toast>
      </ToastContainer>
    );
};


export default ShowToast;