import { $crmhost, $authHost_test, $akihost } from "./index";

export const fetchSale1 = async (
    startDate,
    endDate,
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/sales/sale1', {
      params: {
        startDate,
        endDate,
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };

  export const fetchSale2 = async (
    startDate,
    endDate,
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/sales/sale2', {
      params: {
        startDate,
        endDate,
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };

  export const fetchSale4 = async (
    startDate,
    endDate,
    allParthersCheck
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/sales/sale4', {
      params: {
        startDate,
        endDate,
        allParthersCheck
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };

  export const fetchSale6 = async (
    startDate,
    endDate,
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/sales/sale6', {
      params: {
        startDate,
        endDate,
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };

  export const fetchSale7 = async (
    startDate,
    endDate
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/sales/sale7', {
      params: {
        startDate,
        endDate
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };


  export const fetchSale4_f_man = async (
    ids
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.post('api/sales/sale4_f_man', {
      
       ids
        // limit,
        // page
      
    });
    // console.warn(data);
    return data;
  };


  
  export const fetchSale4AllparthenrsCheck = async (
    id,
    p
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/sales/sale4_shp', {
      params: {
        id,
        p
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };


  export const fetchSale4AkiParthners = async (olap) => {
    const { data } = await $akihost.get('.assets/php/api/olap_parthners.php', {
      params: {
        olap
      },
    });
    // console.warn(data);
    return data;
  };


  export const fetchUp = async (
    mounth
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/sales/up', {
      params: {
        mounth
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };

  
  export const fetchUpAll = async (
    mounth
    // limit = 4,
    // page
  ) => {
    const { data } = await $crmhost.get('api/up/getall', {
      params: {
        mounth
        // limit,
        // page
      },
    });
    // console.warn(data);
    return data;
  };

  
  export const upCreate = async (
      up_id,
      crm,
      parthner,
      yur,
      dcpc,
      dcp,
      crm_id,
      crm_href,
      lid_name,
      logistika,
      dod,
      bank,
      percent,
      term,
      dogovor,
      price,
      price_sms,
      price_pos,
      comission,
      manager,
      di,
      color
    ) =>{
    const {data} = await $crmhost.post('api/up/create', {
      up_id,
      crm,
      parthner,
      yur,
      dcpc,
      dcp,
      crm_id,
      crm_href,
      lid_name,
      logistika,
      dod,
      bank,
      percent,
      term,
      dogovor,
      price,
      price_sms,
      price_pos,
      comission,
      manager,
      di,
      color
    })
    return data
}

export const upEdit = async (
  up_id,
  // crm,
  parthner,
  yur,
  dcpc,
  dcp,
  // crm_id,
  // crm_href,
  lid_name,
  logistika,
  dod,
  bank,
  percent,
  term,
  dogovor,
  price,
  price_sms,
  price_pos,
  comission,
  manager,
  di,
  color,
  double,
  del
) =>{
const {data} = await $crmhost.post('api/up/edit', {
  up_id,
  // crm,
  parthner,
  yur,
  dcpc,
  dcp,
  // crm_id,
  // crm_href,
  lid_name,
  logistika,
  dod,
  bank,
  percent,
  term,
  dogovor,
  price,
  price_sms,
  price_pos,
  comission,
  manager,
  di,
  color,
  double,
  del
})
return data
}


export const fetchLists = async () => {
  const { data } = await $crmhost.get('api/up/lists');
  // console.warn(data);
  return data;
};

export const upCreateV = async (
  up_id,
  dv,
  price,
  di
) =>{
const {data} = await $crmhost.post('api/up/createv', {
  up_id,
  dv,
  price,
  di
})
return data
}


  
export const fetchUpAllV = async (
  mounth
  // limit = 4,
  // page
) => {
  const { data } = await $crmhost.get('api/up/getallv', {
    params: {
      mounth
      // limit,
      // page
    },
  });
  // console.warn(data);
  return data;
};


export const upEdit2 = async (
  id,
  // crm,
  dv,
  price,
  di,
  del
) =>{
const {data} = await $crmhost.post('api/up/edit2', {
  id,
  // crm,
  dv,
  price,
  di,
  del
})
return data
}

export const fetchUpAll2 = async (
  mounth
  // limit = 4,
  // page
) => {
  const { data } = await $crmhost.get('api/up/getall2', {
    params: {
      mounth
      // limit,
      // page
    },
  });
  // console.warn(data);
  return data;
};

export const getChangess = async (
  startdate,
  enddate,
  search
  // limit = 4,
  // page
) => {
  const { data } = await $crmhost.get('api/up/changes', {
    params: {
      startdate,
      enddate,
      search
      // limit,
      // page
    },
  });
  // console.warn(data);
  return data;
};