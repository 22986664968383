import { observer } from "mobx-react-lite";
import React , {useMemo, useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Placeholder, Row, Table, ListGroup,Badge,InputGroup } from "react-bootstrap";

import { Context } from "../index";
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import { INDEX_ROUTE, LOGIN_ROUTE } from "../utils/consts";
import {  fetchSale7} from "../http/salesAPI";
import WidthModal from "../components/modals/WidthModal"

const priceSet = function(data){
    /*
     * В переменной price приводим получаемую переменную в нужный вид:
     * 1. принудительно приводим тип в число с плавающей точкой,
     *    учли результат 'NAN' то по умолчанию 0
     * 2. фиксируем, что после точки только в сотых долях
     */
    var price       = Number.prototype.toFixed.call(parseFloat(data) || 0, 0),
        //заменяем точку на запятую
        price_sep   = price.replace(/(\D)/g, ","),
        //добавляем пробел как разделитель в целых
        price_sep   = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

    return <b>{price_sep}</b>;
};


const Sale7 = observer(() => {

    const location = useLocation()

    let s_d=''
    let e_d=''
    try {
        if (location.search.split('?')[1].split('&')[0].split('=')[0]==='startdate'){
            s_d=location.search.split('?')[1].split('&')[0].split('=')[1]
        }
        if (location.search.split('?')[1].split('&')[1].split('=')[0]==='enddate'){
            e_d=location.search.split('?')[1].split('&')[1].split('=')[1]
        }
    } catch (error) {
        //console.error(error)
    }
    
    const click = async () =>{
       try {
           setTrigger(!trigger)
           setLoading(true)
       } catch (error) {
            console.error(error)
       }
      
       
   }

  

    const {user} = useContext(Context)
    const navigate = useNavigate()

    // const { sales } = useContext(Context);
    const [sales, setSales] = useState([])

    const [table, setTable] = useState([])
    const [table2, setTable2] = useState([])
    
    const [tableHead, setTableHead] = useState([])

    const [modal, setModal] = useState({show:false, header:'', body:''});



    let date = new Date()
   // http://localhost:3000/sale1?startdate=2022-08-12&enddate=2022-08-12
    if (s_d==='' || e_d===''){
        // if (date.getDate()===1){
            // s_d=date.getFullYear()+'-'+(('0' + date.getMonth()).slice(-2))+'-'+(('0' + (date.getDate()-1)).slice(-2))
        s_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + date.getDate()).slice(-2))
        // } else{
            // s_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + (date.getDate()-1)).slice(-2))
        // }
        
        e_d=date.getFullYear()+'-'+(('0' + (date.getMonth()+1)).slice(-2))+'-'+(('0' + date.getDate()).slice(-2))
    } else {

    }

    const [startDate, setStartDate] = useState(s_d)
    const [endDate, setEndDate] = useState(e_d)
    
    const [loading, setLoading] = useState(true)
    const [trigger, setTrigger] = useState(true)

    useEffect(() => {
        location.search="?startdate="+startDate+"=&enddate="+endDate
        navigate(location)
        fetchSale7(startDate,endDate).then((data) => {
            if(data.succsess){
                let thead=[
                    {   
                        id:"city",
                        name:"Город"
                    }
                ]
                Object.keys(data.succsess.coll).map((item2,index2) => {
                    thead.push(
                    // {   
                    //     id:item2+"_count",
                    //     name:"Кол-во новых за "+data.succsess.coll[item2].name
                    // },
                    // {   
                    //     id:item2+"_price",
                    //     name:"Сумма новых за "+data.succsess.coll[item2].name
                    // },
                    // {   
                    //     id:item2+"_143_count",
                    //     name:"Кол-во отказов за "+data.succsess.coll[item2].name
                    // },
                    // {   
                    //     id:item2+"_143_price",
                    //     name:"Сумма отказов за "+data.succsess.coll[item2].name
                    // },
                    {   
                        id:item2+"_percent",
                        name:"% отказов за "+data.succsess.coll[item2].name
                    }
                    )
                })
                 thead.push(
                // {   
                //     id:"itog_count",
                //     name:"Итого новых шт."
                // },
                // {   
                //     id:"itog_price",
                //     name:"Итого новых сумма"
                // },
                // {   
                //     id:"itog_143_count",
                //     name:"Итого отказов шт."
                // },
                // {   
                //     id:"itog_143_price",
                //     name:"Итого отказов сумма"
                // },
                {   
                    id:"itog_percent",
                    name:"Итого % отказов"
                }
                )
                setTableHead(thead)
                let tbody=[]
                let itogo={
                    id:"tr_itogo",
                    city_id:'itogo',
                    city:'Итого'
                }
                let tr_count=0
                Object.keys(data.succsess).map((item2,index2) => {
                    if(item2!=='coll'){
                            let tr={
                                id:"tr_"+item2,
                                city_id:item2,
                                city:data.succsess[item2].name
                            }
                            tr_count++


                            Object.keys(data.succsess.coll).map((item4,index4) => {
                               if (data.succsess[item2].dates[item4]){
                                
                                    // tr[item4+"_count"]=data.succsess[item2].dates[item4].count
                                    // tr[item4+"_price"]=data.succsess[item2].dates[item4].price
                                    // tr[item4+"_143_count"]=data.succsess[item2].dates[item4].count_143
                                    // tr[item4+"_143_price"]=data.succsess[item2].dates[item4].price_143
                                    // tr[item4+"_count_f"]=item2+"_"+item4
                                    // tr[item4+"_143_count_f"]='*'+item2+"_"+item4
                                    tr[item4+"_percent"]=(data.succsess[item2].dates[item4].count_143/data.succsess[item2].dates[item4].count*100).toFixed(2)
                                    if (itogo[item4+"_percent"])
                                        itogo[item4+"_percent"]+=data.succsess[item2].dates[item4].count_143/data.succsess[item2].dates[item4].count*100
                                    else
                                        itogo[item4+"_percent"]=data.succsess[item2].dates[item4].count_143/data.succsess[item2].dates[item4].count*100
                                    
                                } else {
                                    // tr[item4+"_count"]=0
                                    // tr[item4+"_price"]=0
                                    // tr[item4+"_143_count"]=0
                                    // tr[item4+"_143_price"]=0
                                    tr[item4+"_percent"]=0
                                    itogo[item4+"_percent"]=0
                                }
                            })
                            // tr['itog_count']=data.succsess[item2].count
                            // tr['itog_price']=data.succsess[item2].price
                            // tr['itog_143_count']=data.succsess[item2].count_143
                            // tr['itog_143_price']=data.succsess[item2].price_143
                            tr['itog_percent']=(data.succsess[item2].count_143/data.succsess[item2].count*100).toFixed(2)
                            if (itogo['itog_percent'])
                                itogo['itog_percent']+=data.succsess[item2].count_143/data.succsess[item2].count*100
                            else
                                itogo['itog_percent']=data.succsess[item2].count_143/data.succsess[item2].count*100

                            tbody.push(tr)
                    }
                       
                })

                Object.keys(data.succsess.coll).map((item4,index4) => {
                    itogo[item4+"_percent"] = (itogo[item4+"_percent"]/tr_count).toFixed(2)
                })
                itogo['itog_percent'] = (itogo["itog_percent"]/tr_count).toFixed(2)
                console.log(itogo)
                console.log(tr_count)
                tbody.push(itogo)
                 
                       
                
                setTable(tbody)
                setSales(data);
     
    
            } else {
                setTable([])
                setTableHead([])
                setSales(data.error);
            }
            setLoading(false)
        });
        }, [trigger]);


        
       
        function  modalf (i1)  {
            console.log(i1);
            if(!i1) return
            let new_143 = 0
            if (i1[0]=='*'){
                new_143=1
                i1 = i1.substring(1)
            }
            let ind=i1.split("_")
            let mhead = ''
            let mbody=[]
            if (new_143==0){
                mhead='Новые ('+sales.succsess[ind[0]].name+") за "+sales.succsess[ind[0]].dates[ind[1]].name
                Object.keys(sales.succsess[ind[0]].dates[ind[1]].lid).map((item4,index4) => {
                    mbody.push(
                        <tr   key={item4+'_a_'+index4}>
                            <td className="text-center">{index4+1}</td>
                            <td className="text-center">  
                                <a
                                style={{display:"block"}}
                              
                                href={sales.succsess[ind[0]].dates[ind[1]].lid[item4].href}
                                target="_blank"
                                >
                                    {item4} 
                                </a>
                            </td>
                            <td className="text-center">{priceSet(sales.succsess[ind[0]].dates[ind[1]].lid[item4].price)}  руб.</td>
                        </tr>
                      
                        
                        
                    )
    
                })
            }
            else{
                mhead='Отказы ('+sales.succsess[ind[0]].name+") за "+sales.succsess[ind[0]].dates[ind[1]].name
                Object.keys(sales.succsess[ind[0]].dates[ind[1]].lid_143).map((item4,index4) => {
                    mbody.push(
                        <tr   key={item4+'_a_'+index4}>
                            <td className="text-center">{index4+1}</td>
                            <td className="text-center">  
                                <a
                                style={{display:"block"}}
                              
                                href={sales.succsess[ind[0]].dates[ind[1]].lid[item4].href}
                                target="_blank"
                                >
                                    {item4} 
                                </a>
                            </td>
                            <td className="text-center">{priceSet(sales.succsess[ind[0]].dates[ind[1]].lid[item4].price)}  руб.</td>
                        </tr>
                      
                        
                        
                    )
    
                })
            }
            
            let t2=
                <Table key="ss" variant="light" responsive="md"  bordered hover className="sale4tablem">
                    <thead>
                        <tr className="table-dark">
                            <th className="text-center">#</th>
                            <th className="text-center">id & ссылка</th>
                            <th className="text-center">Бюджет</th>
                        </tr>
                    </thead>
                    <tbody key='sad'>
                        {mbody}
                    </tbody>
                </Table>

            setModal({show:true, header:mhead, body:t2})

        }

    return ( 
        <>
        {/* {console.log("render")} */}
        <Container className="pt-2" style={{marginBottom:"70px"}}>
        <Breadcrumb>
        <NavLink className="breadcrumb-item" to={INDEX_ROUTE}>Главная</NavLink>
        <Breadcrumb.Item active>% отказов в разрезе по месяцам</Breadcrumb.Item>
      </Breadcrumb>
             {user.isAuth?
             <>
                <Row>
                    <Col md={12}>
                    <Form className="row">
                    <Form.Group as={Col} xs="6" md="3" className="mt-3" controlId="startDate">
                    <Form.Label>Начальная дата:</Form.Label>
                    <Form.Control
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        type="date"
                        name="startDate"
                        disabled={loading}
                    />    
                    </Form.Group>
                    <Form.Group as={Col} xs="6" md="3" className="mt-3" controlId="endDate">
                    <Form.Label>Конечная дата:</Form.Label>
                    <Form.Control
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        type="date"
                        name="endDate"
                        disabled={loading}
                    />    
                    </Form.Group>
            
                    <Form.Group as={Col} xs="6" md="3" className="text-center mt-5 " controlId="get">
                    <Button  onClick={click} disabled={loading} variant="success" className="">Посчитать</Button>
                    </Form.Group>

                    </Form>
                        <hr/>
                        { (sales['succsess'])?
                        <>                        
                            <div style={{overflow:"auto", maxHeight:"500px"}}>
                                <Table style={{overflow:"auto",fontSize:"0.7em"}} variant={(sales['succsess'])?"light":"danger"} responsive="md"  bordered hover className="sale7table">
                                    <thead>
                                        <tr className="table-dark">
                                            {tableHead.map(tr => (
                                                (!loading)?
                                                    <th 
                                                        key={tr.id}
                                                        title={tr.name}
                                                        className={"text-center"}
                                                        >
                                                        {tr.name} 
                                                    </th> 
                                                    :      
                                                    <th key={tr.id} style={{color:'white'}} className="text-center">
                                                    <Placeholder  animation="glow">
                                                        <Placeholder xs={2} />
                                                    </Placeholder>
                                                </th>          
                                                ))}
                                        </tr>
                                    </thead>        
                                    <tbody>
                                        {table.map(tr => (
                                                    <tr key={tr.id} className={(tr.id=='tr_itogo')?'table-dark':''}>
                                                        {tableHead.map(td => (
                                                            (!loading)?
                                                            <td 
                                                                style={{background:(td.id=="status")?tr.color:(td.id[0]==="i" && tr.id!="tr_itogo")?'#9ff5e4':'',color:(tr[td.id]===0)?'silver':"inherit",cursor:(tr[td.id+"_f"])?"pointer":''}}
                                                                key={td.id}
                                                                className="text-center"
                                                                kk={td.id+"_f"}
                                                                onClick={() => modalf(tr[td.id+"_f"])}
                                                            >{
                                                                (td.id[td.id.length-1]==="e")?
                                                                priceSet(tr[td.id])
                                                                :
                                                                tr[td.id]
                                                                }
                                                                </td>  
                                                            : 
                                                            <td  key={td.id} className="text-center">
                                                            <Placeholder  animation="glow">
                                                                <Placeholder xs={2} />
                                                            </Placeholder>
                                                            </td>  
                                                        ))}  
                                                    </tr>         
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            </>:      
                            <Table style={{overflow:"auto",fontSize:"0.7em"}} variant={(sales['succsess'])?"light":"danger"} responsive="md"  bordered hover>
                                <thead>
                                    {(!loading)?
                                        <tr>
                                            <th key={Math.random()}>{sales}</th>
                                        </tr>
                                    :
                                        <tr>
                                            <th className="text-center" key={Math.random()}>
                                                <Placeholder  animation="glow">
                                                    <Placeholder xs={2} />
                                                </Placeholder>
                                            </th>
                                        </tr>
                                    }
                                </thead>
                             </Table>
                        }
                    </Col>
                    {/* <Col md={4}>
                        <Calendar/>
                    </Col> */}
                    
                </Row>
                <Row>
                    
                </Row>
                <Row>  
                </Row>
                <Row>
                </Row>
                <WidthModal show={modal.show} onHide={() => setModal({show:false})} header={modal.header} body={modal.body}/>
            </>:
           navigate(LOGIN_ROUTE)
            }
        </Container>
        </>
        // <></>
    // }
    );
});


export default Sale7; 