import { $authHost, $host, $authHost_test } from "./index";
import axios from 'axios';

export const GetListOfBanks = async () => {
  const { data } = await $authHost.get('/api/banks/list');
  // console.warn(data);
  return data;
};

export const UpdateBank = async (oldName, newName) => {
  const { data } = await $authHost.post('/api/banks/update', { oldName, newName })
  return data;
}

export const RemoveBank = async (name) => {
  const { data } = await $authHost.post('/api/banks/remove', { name })
  return data;
}

export const AddBank = async (name) => {
  const { data } = await $authHost.post('/api/banks/create', { name })
  return data;
} 