import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import {Route, Routes, Reditect} from 'react-router-dom'
import { Context } from "../index";
import { adminRoutes,rukRoutes,rukRoutes2, publicRoutes, managerRoutes } from "../routes";


const AppRouter = observer(() => {
    const {user} = useContext(Context)
    return(
        <Routes>
            {user.isAuth && user.user.role==='ADMIN' && adminRoutes.map(({path, Component}) =>
                  <Route key={path} path={path} element={Component} exact/>
            )}
            {user.isAuth && user.user.role==='RUK' && rukRoutes.map(({path, Component}) =>
                  <Route key={path} path={path} element={Component} exact/>
            )}
            {user.isAuth && user.user.role==='RUK+' && rukRoutes2.map(({path, Component}) =>
                  <Route key={path} path={path} element={Component} exact/>
            )}
            {user.isAuth && user.user.role==='MAN' && managerRoutes.map(({path, Component}) =>
                  <Route key={path} path={path} element={Component} exact/>
            )}
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={Component} exact/>
            )}
          </Routes>
           
    );

});

export default AppRouter;