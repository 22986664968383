import React, { useContext, useEffect, useState } from 'react'
import AppRouter from './components/AppRouter'
import { BrowserRouter } from 'react-router-dom'
import NavBar from './components/NavBar';
import { observer } from 'mobx-react-lite';
import { Context } from './index';
import { check } from './http/userAPI';
import { Spinner } from 'react-bootstrap';
import MobNavBar from './components/MobNavBar';


const App = observer(() =>{
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)

  useEffect(() =>{
      check().then(data => {
        user.setUser(data)
        user.setIsAuth(true)
      }).finally(()=> setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (loading){
    return <Spinner animation={"grow"}/>
  }

  return (
    <BrowserRouter>
      <NavBar/>
      <MobNavBar/>
       <AppRouter/>
    </BrowserRouter>
     
  );
});

export default App;
