import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState }  from "react";
import {Button, Card, Container, Form, Row , Col} from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../index";
import { login, registration } from "../http/userAPI";
import {INDEX_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE} from '../utils/consts'
import ShowToast from "../components/modals/ShowToast";



const Auth = observer(() => {
    const {user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = location.pathname !== REGISTRATION_ROUTE
    // console.log(location)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [surname, setSurname] = useState('')
    const [name, setName] = useState('')
    const [birthday, setBirthday] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState(false)
    const [position, setPosition] = useState('')

    const [toast, setToast] = useState({show:false, header:'', body:''});

    const click = async () =>{
        //  console.warn({gender})
        // console.warn(email)
        if (!/^[-\w.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) { 
            // alert("Не валидный EMAIL!")
            setToast({show:true, header:"Ошибка!!!", body:"Невалидный EMAIL!"})
            return false
         }

        try {
            let data;
            if (isLogin){
                // console.warn(1)
                data = await login(email, password)
                
            } else {
                // console.warn(surname)
                let gender2 
                if (gender){
                    gender2 = 1
                } else{
                    gender2 = 0
                }
                data = await registration(email, password, name, surname, birthday, gender2, phone, position)
            }
            user.setUser(data)
            user.setIsAuth(true)

            if (location.pathname!==REGISTRATION_ROUTE && location.pathname!==LOGIN_ROUTE){
                navigate(location.pathname+location.search)
            } else {
            navigate(INDEX_ROUTE)
            }
        } catch (error) {
            setToast({show:true, header:"Ошибка!!!", body:error.response.data.message})
            // alert(error.response.data.message)
        }
       
        
    }

    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            console.log("Enter key was pressed. Run your function.");
            click()
            event.preventDefault();
           
                                    // callMyFunction();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [email, password, surname, name, birthday, phone, gender, position]);

    return (
        <Container className="d-flex justify-content-center align-items-start" style={{height:window.innerHeight-54}}>
            <Card style={{width:600}} className="p-5">
                <h2 className="m-auto">{isLogin ? 'Авторизация':'Регистрация'}</h2>
                <Form className="d-flex flex-column">
                    <Form.Control
                        className="mt-3"
                        placeholder="Введите ваш email..."
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        name="login"
                        id="login"
                        aria-describedby="loginHelp"
                    />    
                    <Form.Control
                        className="mt-3"
                        placeholder="Введите ваш пароль..."
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        required
                    />  
                    {!isLogin?
                        <>
                            <hr/>
                            <Form.Control
                            className="mt-3"
                            placeholder="Введите вашу фамилию..."
                            value={surname}
                            onChange={e => setSurname(e.target.value)}
                            type="text"
                            required
                            /> 
                            <Form.Control
                            className="mt-3"
                            placeholder="Введите ваше имя..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                            type="text"
                            required
                            /> 
                            <Form.Group as={Col} className="mt-3" controlId="birthday">
                                <Form.Label>Введите вашу дату рождения:</Form.Label>
                                <Form.Control
                            placeholder="Введите вашу дату рождения..."
                            value={birthday}
                            onChange={e => setBirthday(e.target.value)}
                            type="date"
                            required
                            /> 
                            </Form.Group>
                            
                            <Form.Control
                            className="mt-3"
                            placeholder="Введите ваш номер телефона..."
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            type="text"
                            required
                            /> 
                            <Form.Control
                            className="mt-3"
                            placeholder="Введите вашу должность..."
                            value={position}
                            onChange={e => setPosition(e.target.value)}
                            type="text"
                            required
                            /> 
                            <br/>
                            <Form.Group className="mb-3">
                                <Form.Label 
                                    htmlFor="gender"
                                    style={{"marginRight":"5px"}}
                                >Женский</Form.Label>        
                                <Form.Check 
                                    checked={gender}
                                    type="switch"
                                    id="gender"
                                    label="Мужской"
                                    style={{"display":"inline-block"}}
                                    onChange={e => setGender(e.target.checked)}
                                />
                            </Form.Group>
                        </>           
                        :''
                    }
                    <Row className="d-flex  justify-content-between  mt-3 pr-3 pl-3 ">
                       {isLogin ?
                            <div>
                                Нет аккаунта? <NavLink to={REGISTRATION_ROUTE}>Зарегистрируйтесь!</NavLink>
                            </div>
                            :
                            <div>
                                Есть аккаунт? <NavLink to={LOGIN_ROUTE}>Войдите!</NavLink>
                            </div>
                        }
                    </Row>
                        <Button onClick={click} className=" align-self-end mt-3" variant={"outline-success"}>
                           {isLogin ? ' Войти' : 'Регистрация'}
                        </Button>
                    
                    
                </Form>
            </Card>
            <ShowToast show={toast.show} onClose={() => setToast({show:false})} header={toast.header} body={toast.body}/>
        </Container>
    );
});


export default Auth;