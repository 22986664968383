// import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';


const WidthModal = ({show, onHide, header, body}) => {

    // const [show, setShow] = useState(false);
    // const [header, setHeader] = useState(header);
    // const [body, setBody] = useState(body);

    // const [value, setValue] = useState('')
    // const [toast, setToast] = useState({show:false, header:'', body:''});

    // const addTag = () => {
    //     try {
    //         let tag = createTag({name:value})
    //         // let tag = createTag({name:value}).then(data => setValue(''))

    //             setToast({show:true, header:"Ошибка!!!", body:"Невалидный EMAIL!"})
    //             console.log(tag)
    //             onHide()

            
    //     } catch(error){
    //         setToast({show:true, header:"Ошиб4ка!!!", body:error.response.data.message})
    //     }
       
    // }
    

    return (
        <>
          {/* <Button variant="primary" onClick={() => setShow(true)}>
            Custom Width Modal
          </Button> */}
    
          <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            style={{width:'90%'}}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
              {header}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body  style={{maxHeight:"500px",overflowY:"auto", paddingTop:"0px"}}>
              {body}
            </Modal.Body>
          </Modal>
        </>
      );
};


export default WidthModal;