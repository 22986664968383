import {makeAutoObservable} from 'mobx';

export default class SalesStore {
    constructor () {
        this._sale1 = [{
            "summ": 180,
            "rows": {
                "ф.Ульяновск": {
                    "count": 77777,
                    "name": "ф.Ульяновск"
                },
                "ф.Магнитогорск": {
                    "count": 27,
                    "name": "ф.Магнитогорск"
                },
                "ф.Пенза": {
                    "count": 34,
                    "name": "ф.Пенза"
                },
                "ф.Ярославль": {
                    "count": 17,
                    "name": "ф.Ярославль"
                },
                "КЦ Пермь": {
                    "count": 21,
                    "name": "КЦ Пермь"
                },
                "КЦ Ульяновск": {
                    "count": 26,
                    "name": "КЦ Ульяновск"
                },
                "ф. Уфа": {
                    "count": 25,
                    "name": "ф. Уфа"
                },
                "ф.Самара": {
                    "count": 16,
                    "name": "ф.Самара"
                },
                "ф.Пермь": {
                    "count": 5,
                    "name": "ф.Пермь"
                }
            }
        }]
        makeAutoObservable(this)
    }
    // SETTERS
    setSale1(sale1){
        this._sale1 = sale1
    }

    // GETTERS
    get sale1(){
        return this._sale1
    }
}