import React, { useState, useContext } from "react";
import { Button, Container } from "react-bootstrap";
import { Context } from "../index";
import CreateTag from "../components/modals/CreateTag";
import CreateTagUserLink from "../components/modals/CreateTagUserLink";
import CreateTagUsers from "../components/modals/CreateTagUsers";
import CreateTagBanks from "../components/modals/CreateTagBanks";


const Admin = () => {
  const { user } = useContext(Context)
  const [tagVisible, setTagVisible] = useState(false)
  const [tagUserLinkVisible, setTagUserLinkVisible] = useState(false)
  const [tagUsersVisible, setTagUsersVisible] = useState(false)
  const [tagBanksVisible, setTagBanksVisible] = useState(false)
  return (

    <Container className="d-flex flex-column">
      {
        (user.isAuth && (user.user.role === 'ADMIN' || user.user.role === 'RUK+')) ?
          <>
            <Button onClick={() => setTagVisible(true)} variant={"outline-dark"} className="mt-4 p-2">Распределение менеджеров («Привлечение IT»)</Button>
            <CreateTag show={tagVisible} onHide={() => setTagVisible(false)} />
          </>
          : ''}{
        (user.isAuth && user.user.role === 'ADMIN') ?
          <>
            <Button onClick={() => setTagUserLinkVisible(true)} variant={"outline-dark"} className="mt-4 p-2">Связка данных пользователей</Button>
            <Button onClick={() => setTagUsersVisible(true)} variant={"outline-dark"} className="mt-4 p-2">Пользователи</Button>
            <Button onClick={() => setTagBanksVisible(true)} variant={"outline-dark"} className="mt-4 p-2">Банки</Button>
            <CreateTagUserLink show={tagUserLinkVisible} onHide={() => setTagUserLinkVisible(false)} />
            <CreateTagUsers show={tagUsersVisible} onHide={() => setTagUsersVisible(false)} />
            <CreateTagBanks show={tagBanksVisible} onHide={() => setTagBanksVisible(false)} />
          </> : ''
      }
    </Container>


  );
};


export default Admin;