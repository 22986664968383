import React, { useEffect, useState } from 'react';
import { Form, Pagination } from "react-bootstrap";
// import './TablePagination.style.scss';
import usePagination, { DOTS } from "./usePagination";
const TablePagination = ({
   totalCount,
   changeItemsPerPage,
   pageSize,
   onPageChange,
   currentPage,
   siblingsCount = 1
}) => {

  const [totalPages, setTotalPages] = useState(Math.ceil(totalCount / pageSize));

  useEffect(() => {
    setTotalPages(Math.ceil(totalCount / pageSize));
  }, [pageSize]);
  const paginationRange = usePagination({ currentPage, totalCount, siblingsCount, pageSize })
  const handlePrevClick = () => {
    let changedPage = currentPage > 1 ? currentPage - 1 : currentPage
    onPageChange(changedPage);
  }
  const handleNextClick = () => {
    onPageChange(currentPage*1 + 1);
  }
  const handleChangePageSize = (e) => {
    changeItemsPerPage(+e.target.value);
  }
  return (
    <div className='custom-table__pagination'>
      <Pagination style={{ display:"inline-flex"}} key={currentPage}>
        <Pagination.First onClick={() => onPageChange(1)} disabled={(totalCount <= pageSize)}/>
        <Pagination.Prev onClick={() => handlePrevClick()} disabled={currentPage === 1}/>
        
        <Form.Select
            style={{width: "100px"}}
            className='select-perpage2'
            defaultValue={currentPage}
            onChange={(e) => {onPageChange(e.target.value*1)}}
      >
        {paginationRange.map((pageNumber, index) => {
          let current = index + 1;
        //   if (pageNumber === DOTS) {
        //     return <Pagination.Item key={index}>&#8230;</Pagination.Item>;
        //   }
          return <option
           key={index}
           value={current}>{current}
           </option>
        })}
        </Form.Select>
        <Pagination.Next onClick={() => handleNextClick()} disabled={currentPage === totalPages}/>
        <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={(totalCount <= pageSize)}/>
      </Pagination>
      <Form.Select
        className='select-perpage'
        defaultValue={pageSize}
        onChange={(e) => handleChangePageSize(e)}
        style={{width: "90px", float:"right"}}
      >
        <option value="1">1</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="100">100</option>
      </Form.Select>
    </div>
  );
};
export default TablePagination;