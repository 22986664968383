import { observer } from "mobx-react-lite";
import React , { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import IndexBar from "../components/IndexBar";
import { Context } from "../index";
import {useNavigate } from "react-router-dom"
import { LOGIN_ROUTE } from "../utils/consts";
import Calendar from "../components/Calendar";

const Index = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()
    return (
       
        <Container className="pt-2" style={{marginBottom:"70px"}}>
            {console.log(user.isAuth)}
             {user.isAuth===true?
             <>
                <Row>
                    <Col md={8}>
                        <IndexBar/>
                        <hr/>
                    </Col>
                    <Col md={4}>
                        <Calendar/>
                    </Col>
                    
                </Row>
                <Row>
                    
                </Row>
                <Row>  
                </Row>
                <Row>
                </Row>
            </>:
           navigate(LOGIN_ROUTE)
            }
            
        </Container>
 
        // <></>
    // }
    );
});


export default Index;